import React, { Component } from "react";
import { connect } from "react-redux";

import { getMovies } from "../redux/actions/movies";
import { getCinemas } from "../redux/actions/cinemas";
import { getMoviesFilter } from "../redux/actions/movies4filter";
import { getMoviesFilterByCinema } from "../redux/actions/movies4filterCinema";
import MovieItem from "./MovieItem";
import ComingSoonMovieItem from "./ComingSoonMovieItem";

import NoMovies from '../components/nomovies-modal'

class MovieList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: "",
      date: "",
      sevenDays: [],
      today: new Date(),
      filter: false,
      filterByCinema: false,
      location: "All Cinemas",
      locations: [],
      locationsObjs: [],
      states: [],
      cinemas: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    //this.doToday();
    this.get7days();
    const { dispatch } = this.props;

    dispatch(getMovies());
    dispatch(getCinemas());

    //this.getStates();
    //dispatch(getMoviesFilter());
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cinemas !== prevProps.cinemas) {
      this.setState({ cinemas: this.props.cinemas });
      this.getStates();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });

    if (name === "location") {
      this.props.dispatch(getMoviesFilterByCinema(value));
      let filtered4loc = this.props.cinemas.filter(item => item.uid === value)[0];
      this.setState({filtered4loc: filtered4loc})
      this.setState({state: filtered4loc.state});
      this.setState({ filterByCinema: true });
      this.setState({ filter: false });
    }

    if (name === "state") {
      let { cinemas } = this.props;
      let filtered = cinemas.filter(item => item.state === value);
      this.setState({ cinemas: filtered });
    }
  }

  handleDayClick = (item, e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    console.log('date stuff ... item: ', item);
    console.log('date: ', item.date);

    dispatch(getMoviesFilter(item.date));
    this.setState({date: item.date});
    this.setState({ filter: true });
    this.setState({ filterByCinema: false });
  };

  doToday() {
    let date = new Date();
    this.setState({ today: date });
  }

  get7days() {
    let date = this.state.today;
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    let sevenDays = [];

    for (let i = 0; i <= 6; i++) {
      let aDate = new Date(date.setDate(date.getDate() + i));
      let weekIndex = aDate.getDay();
      let weekDay = days[weekIndex];

      if (i === 0) {
        sevenDays.push({
          date: aDate.toISOString(),
          day: "Today",
          dayInitial: weekDay
        });
      } else if (i === 1) {
        sevenDays.push({
          date: aDate.toISOString(),
          day: "Tomorrow",
          dayInitial: weekDay
        });
      } else if (i > 1) {
        let newDate = new Date(date.setDate(aDate.getDate() - (i - 1)));
        let newDay = days[newDate.getDay()];
        sevenDays.push({
          date: newDate.toISOString(),
          day: newDay,
          dayInitial: newDay
        });
      }
    }
    this.setState({ sevenDays: sevenDays });
  }

  getStates() {
    let { cinemas } = this.props;
    let states = [];

    cinemas &&
      cinemas.map((item, index, arr) => {
        if (!states.includes(item.state)) {
          states.push(item.state);
        }
        this.setState({ states: states });
        return states;
      });
  }

  getCinemasInState(state) {
    let { cinemas } = this.props;
    let locations = [];
    let locationsObjs = [];

    let optionCinemas = cinemas.map((item, index, arr) => {
      if (item.state === state) {
        if (!locations.includes(item.name)) {
          locations.push(item.name);
          locationsObjs.push(item);
        }
        this.setState({ locations: locations });
        this.setState({ locationsObjs: locationsObjs });

        return (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        );
      }
      return null;
    });
    return optionCinemas;
  }

  render() {
    let {
      movies,
      featuredmovies,
      moviesFilter,
      moviesFilterByCinema,
      cinemas
    } = this.props;
    let { filterByCinema, filter, states } = this.state;

    let movies2display = filter ? moviesFilter : movies;

    if (filter) {
      movies2display = moviesFilter;
    }

    if (filterByCinema) {
      movies2display = moviesFilterByCinema;
      // if (moviesFilterByCinema.length > 0) {
      //   movies2display = moviesFilterByCinema;
      // }
    }

    let toRender =
      movies2display.length > 0 ? (
        movies2display.map((item, index, arr) => {
          let selectedCinema = cinemas.filter(item => item.uid === this.state.location)[0];
                   
          let dselected = {
            state: this.state.state, location: this.state.location, movie: item,
            date: this.state.date,
            movieId: item.id? item.id: item._id, cinemas,selectedCinema
          }
          return <MovieItem movie={item} key={item.id} selectedStateAndLocation={dselected} />;
        })
      ) : (
          <p>{(this.state.filter || this.state.filterByCinema) && "No result"}</p>
        );

    let toRenderFeatured = featuredmovies.map(function (item, index, arr) {
      return <ComingSoonMovieItem movie={item} key={item.id} />;
    });

    return (
      <div id="movielist">
        {movies.length ? "" : ""/*<NoMovies />*/}
        <div className="container movies-section">
          <div className="row">
            <div className="section-title centered-title">
              {/* <span>Movies at the Cinemas</span> */}
              <nav class="nav movies-tab">
                <a
                  class="nav-link active"
                  role="tab"
                  data-toggle="tab"
                  href="#nowshowing"
                >
                  Now Showing
                </a>
                {/* <a class="nav-link" role="tab" data-toggle="tab" href="#comingsoon">
                  Coming Soon
                </a> */}
              </nav>
            </div>
          </div>

          <div className="row" />
        </div>

        <div className="container">
          <nav class="nav movies-tab days-tab scroll-hidden">
            <div className="day-left">
              Showing:
              {this.state.sevenDays.map(item => {
                let selected = this.state.date;
                console.log('selected ...: ', selected);
                return (
                  <a
                    className={selected === item.date ? "active" : ""}
                    role="tab"
                    data-toggle="tab"
                    href="#nowshowing"
                    onClick={this.handleDayClick.bind(null, item)}
                  >
                    {item.day}
                  </a>
                );
              })}
              {/* <a class="active" role="tab" data-toggle="tab" href="#nowshowing">Today</a>
              <a role="tab" href="#/">Tomorrow</a>
              <a role="tab" href="#/">Thursday</a>
              <a role="tab" href="#/">Friday</a>
              <a role="tab" href="#/">Saturday</a>
              <a role="tab" href="#/">Sunday</a> */}
            </div>
            <div className="day-right">
              <span className="filter-section">
                <div class="form-group">
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    name="state"
                    value={this.state.state}
                    onChange={this.handleInputChange}
                  >
                    <option value="">States</option>
                    {states.length > 0 &&
                      states.map(item => {
                        return <option value={item}>{item}</option>;
                      })}
                    {/* <option>State</option>
                    <option>Lagos</option>
                    <option>Ilorin</option>
                    <option>Enugu</option>
                    <option>Ibadan</option> */}
                  </select>
                </div>
                <div class="form-group">
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    name="location"
                    value={this.state.location}
                    onChange={this.handleInputChange}
                  >
                    <option value="">All Cinemas</option>
                    {this.state.cinemas.length > 0 &&
                      this.state.cinemas.map((item, index) => {
                        return <option value={item.uid}>{item.name}</option>;
                      })}
                  </select>
                </div>
              </span>
            </div>
          </nav>
        </div>

        <div class="tab-content">
          <div class="tab-pane fade show active container pt-5" id="nowshowing">
            <div className="row movies">{toRender}</div>
            <div className="row" />
          </div>
          <div class="tab-pane fade container pt-5" id="comingsoon">
            <div className="row movies">{toRenderFeatured}</div>
            <div className="row" />
          </div>
        </div>

        {/* <div className="container pt-5">
          <div className="row movies">{toRender}</div>

          <div className="row" />
        </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let moviesFilter = state.moviesFilter;
  let theMovies = state.movies.movies;
  let featureMovies = theMovies.filter(function (item, index, arr) {
    return !!item.featured;
  });

  return {
    movies: theMovies.slice(0, 15),
    featuredmovies: featureMovies.slice(0, 4),
    moviesFilter: moviesFilter.movies,
    moviesFilterByCinema: state.moviesFilterByCinema.movies,
    cinemas: state.cinemas.cinemas
  };
}

export default connect(mapStateToProps)(MovieList);
