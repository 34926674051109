export const SIGNUP_PENDING = 'SIGNUP_PENDING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const GET_MOVIES_PENDING = 'GET_MOVIES_PENDING';
export const GET_MOVIES_SUCCESS = 'GET_MOVIES_SUCCESS';
export const GET_MOVIES_FAILED = 'GET_MOVIES_FAILED';

export const GET_MOVIE_PENDING = 'GET_MOVIE_PENDING';
export const GET_MOVIE_SUCCESS = 'GET_MOVIE_SUCCESS';
export const GET_MOVIE_FAILED = 'GET_MOVIE_FAILED';


export const GET_MOVIES_FEATURED = 'GET_MOVIES_FEATURED';

export const GET_FEATURED_MOVIES_PENDING = 'GET_FEATURED_MOVIES_PENDING';
export const GET_FEATURED_MOVIES_SUCCESS = 'GET_FEATURED_MOVIES_SUCCESS';
export const GET_FEATURED_MOVIES_FAILED = 'GET_FEATURED_MOVIES_FAILED';

export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

export const GET_TICKET_SHOWTIME_PENDING = 'GET_TICKET_SHOWTIME_PENDING';
export const GET_TICKET_SHOWTIME_SUCCESS = 'GET_TICKET_SHOWTIME_SUCCESS';
export const GET_TICKET_SHOWTIME_FAILED = 'GET_TICKET_SHOWTIME_FAILED';


export const GET_SOLDOUT_PENDING = 'GET_SOLDOUT_PENDING';
export const LOADING_SOLDOUT = 'LOADING_SOLDOUT';
export const LOADING_SOLDOUT_END = 'LOADING_SOLDOUT_END';
export const GET_SOLDOUT_SUCCESS = 'GET_SOLDOUT_SUCCESS';
export const GET_SOLDOUT_FAILED = 'GET_SOLDOUT_FAILED';

export const GET_MOVIES_FILTER_PENDING = 'GET_MOVIES_FILTER_PENDING';
export const GET_MOVIES_FILTER_SUCCESS = 'GET_MOVIES_FILTER_SUCCESS';
export const GET_MOVIES_FILTER_FAILED = 'GET_MOVIES_FILTER_FAILED';

export const GET_MOVIES_FILTER_BYCINEMA_PENDING = 'GET_MOVIES_BYCINEMA_PENDING';
export const GET_MOVIES_FILTER_BYCINEMA_SUCCESS = 'GET_MOVIES_BYCINEMA_SUCCESS';
export const GET_MOVIES_FILTER_BYCINEMA_FAILED = 'GET_MOVIES_BYCINEMA_FAILED';

export const GET_CINEMAS_PENDING = 'GET_CINEMAS_PENDING';
export const GET_CINEMAS_SUCCESS = 'GET_CINEMAS_SUCCESS';
export const GET_CINEMAS_FAILED = 'GET_CINEMAS_FAILED';

export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const USER_PENDING = 'USER_PENDING';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILED = 'USER_FAILED';
export const USER_CLEAR = 'USER_CLEAR';

export const USER_UNSUBSCRIBE_SUCCESS = 'USER_UNSUBSCRIBE_SUCCESS';
export const USER_UNSUBSCRIBE_FAILED = 'USER_UNSUBSCRIBE_FAILED';

export const USER_RESUBSCRIBE_SUCCESS = 'USER_RESUBSCRIBE_SUCCESS';
export const USER_RESUBSCRIBE_FAILED = 'USER_RESUBSCRIBE_FAILED';//USER_SUBFAILURE
export const USER_SUBFAILURE = 'USER_SUBFAILURE';





