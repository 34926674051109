import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import swal from 'sweetalert';

import Payment from './payment';
import PaymentPaystack from './paymentPaystack';

let delay = ms => new Promise(res => setTimeout(res, ms));
class RavePay extends React.Component {
    state = {
        showModal: false,
        allFilled: false,
        disableComplete: false,
        showPayments: false,
        selectedPayment: 0,
        toSend: {}
    }
    handleSubmitError = () => {
        console.log("OJUJHJHKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK", this.checkAll());
        this.forceUpdate();
        if (!this.props.agree2terms) {
            alert('Please agree to the terms and conditions to continue');
            return;
        }
        else {
            if(this.props.voucher != "" && (!this.props.isVoucherApplied)) {
                alert('Please fill all the form fields and apply your voucher or clear the voucher box to continue.');
                
            } else if (!this.state.allFilled) {
                alert('Please fill all the form fields');
            } else {
                console.log('something is not right');
            }
        }

    }

    selectPayment = () => {
        this.forceUpdate();
        if (!this.props.agree2terms) {
            alert('Please agree to the terms and conditions to continue');
            return;
        }
        else {
            if(this.props.voucher != "" && (!this.props.isVoucherApplied)) {
                alert('Please fill all the form fields and apply your voucher or clear the voucher box to continue.');

            } else if (!this.state.allFilled) {
                alert('Please fill all the form fields');
            } else {
                console.log('something is not right');
            }
        }

    }

    checkAll = async () => {
        let userDetails = this.props.userDetails
        console.log("1111111111111111111", userDetails)
        if (Array.isArray(userDetails) && userDetails.length) {
            let u = userDetails[0]
            if(!u.email || !u.fullname || !u.phone){
                return {status: false, msg: "Invalid userdetails provided please check and update"}
            }
        }else{
            return {status: false, msg: "Invalid userdetails provided please check and update"}
        }

        let ticketInfo = this.props.ticketInfo 

        let amount = this.props.amount

        let totalTickets = this.props.totalTickets
        if(!totalTickets){
            window.location.reload()
        }

        let cinemaId = this.props.cinemaId
        if(!cinemaId){
            window.location.reload()
        }

        let amountBeforeVoucher = this.props.amountBeforeVoucher
        if(!amountBeforeVoucher){
            window.location.reload()
        }
        let filmHouseId = this.props.filmHouseId
        let genesisId = this.props.genesisId
        let selectedCinema = this.props.selectedCinema
        if(!selectedCinema){
            //window.location.reload()
        }
        let selectedTickets = this.props.selectedTickets
        if(!selectedTickets.length && selectedTickets[0].price){
            window.location.reload()
        }
        let selectedShowtime = this.props.selectedShowtime
        if (!selectedShowtime || selectedShowtime.limit < 1){
            window.location.reload()
        }
        let movie = this.props.movie
        let carType = this.props.carType

        let isVoucherApplied = this.props.isVoucherApplied
        let voucher = this.props.voucher
        if (isVoucherApplied && !voucher){
            window.location.reload()
        }
        let ref = (+ new Date()).toString(36) + Math.floor(Math.random() * Math.floor(9));
        let toSend = {
            userDetails: userDetails[0],
            selectedTickets: selectedTickets,
            showtime: selectedShowtime,
            isVoucherApplied: isVoucherApplied,
            voucher: voucher,
            carType: carType,
            filmHouseId: filmHouseId,
            amountBeforeVoucher: amountBeforeVoucher,
            amount: amount,
            totalTickets: totalTickets,
            ref: ref
        }

        this.setState({toSend: toSend})
        try {
            let res = await axios.post(`${global.nairaboxENV(1)}/v2/prepayment`, {toSend: toSend})
            console.log("########################### 1", res.data)
        } catch (err) {
            console.log("########################### 2", err.response && err.response.data && err.response.data)
        }

        return {status: true, msg: "toSend created"}
    }

    handleFullPaymentVoucher = async () => {
        let r = await this.checkAll()
        if(!r.status){
            swal('Form error', r.msg, "error")
            return
        }

        if(!this.state.disableComplete){
        try {
            this.setState({disableComplete: true});
            //await delay(1000);
            const toSend = { ...this.state.toSend, ...this.props }
            if (toSend.userDetails) {
                toSend.userDetails = toSend.userDetails[0];
            }
            let res = await axios.post(`${global.nairaboxENV(1)}/v2/tickets/?as=voucher-ticket`, {toSend})

                console.log(res, 'response')    
                swal('SUCCESS','Ticket Purchase Succesful, Please check your mail for your E-ticket and close this prompt to download ticket', "success")
                    window.location.href = res.data.url;

                // if (response.data.status === 404) {
                //     sweetAlert("Error!", response.data.message, "error");
                // }
                // else if (response.data.status === 200) {
                //     console.log(response.data.message)
                //     _this.setState({ isVoucherApplied: true, discount: response.data.message })
                //     //   sweetAlert("Ticket Purchase Succesful!", response.data.message, "success").then(() => {
                //     //     window.location.href = '/';
                //     //   });
                // }

        } catch (err) {
            this.setState({disableComplete: false});
            console.log("++++++++++++++++++++++++++++++++++++++++++++++", err.message);
            if (err.response && err.response.data && err.response.data.message) {

                swal("ERROR",'Ticket purchase  Failed:- ' + err.response.data.message, "error")
            } else {
                swal("ERROR",'Ticket purchase  Failed:- ' + err.message, "error")
            }

        }
        }else{
            swal('Button error', "You have already submited this form", "error")
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.showModal === false || (prevState.showModal !== this.state.showModal ||
            prevProps.userDetails !== this.props.userDetails) ||
            // prevProps.userDetails[0].phone !== this.props.userDetails[0].phone ||
            prevProps.agree2terms !== this.props.agree2terms ||
            prevProps.totalTickets !== this.props.totalTickets) {

            let totalTickets = this.props.totalTickets;

            if (this.state.showModal) {
                this.setState({ showModal: false })
            }

            let names = this.props.userDetails && this.props.userDetails[0];
            let requiredFormValues = ["email", "fullname", "phone"];

            if (this.props.userDetails.length === 1 && !this.props.showMultiple) {
                //this.setState({ showModal: false });

                let validNames = requiredFormValues.every((value) => {
                    return value in names
                })

                if (validNames) {
                    //console.log('all fields are filled ');
                    if (!this.props.agree2terms) {
                        //this.setState({ showModal: false });
                        return;
                    }

                    this.setState({ showModal: true });
                    if (this.state.allFilled &&
                        this.state.allFilled !== prevState.allFilled) {
                        //console.log('allfilled...');
                        this.forceUpdate();
                    }


                }
                else {
                    console.log('there are required field to be filled ')
                    //console.log('names: ', names)
                    if (prevProps.agree2terms !== this.props.agree2terms) {
                        if (this.props.agree2terms) {
                            //console.log('agree true...');
                            if (this.state.allFilled) {
                                //console.log('agree allfiled true...')
                            }
                            else {
                                this.forceUpdate();
                                alert('Please first fill the required fields');
                                this.props.revertAgree();
                                this.forceUpdate();


                            }
                        }
                        else {
                            // console.log('agree is not true here..')
                        }
                    }
                }

            }

            if ((totalTickets > 1) && (totalTickets === this.props.userDetails.length)
                && this.props.showMultiple) {

                let names = this.props.userDetails;
                let requiredFormValues = ["email", "fullname", "phone"];

                let validNames = names.map((item) => {
                    return requiredFormValues.every(function (value) {
                        return value in item;
                    })

                })

                let allTrue = validNames.every((item) => {
                    return !!item === true;
                });

                //console.log('allTrue: ', allTrue);

                if ((validNames.length >= names.length) && allTrue) {

                    this.setState({ showModal: true });
                }

            }
            if (prevProps.userDetails !== this.props.userDetails) {
                //console.log(`payment updated, this.props.userDetails: `, this.props.userDetails)
            }
        }
    }

    render() {

        let readyButton = (<div>
            <p style={{ color: 'red' }}>You are yet to fill all the necessary details</p>
            <button className="ticket-button block-btn" onClick={this.handleSubmitError}>Make Payment</button>
        </div>
        )

        let errorVoucher = (<div>
            <p style={{ color: 'red' }}>You have not applied you voucher code yet, apply or clear the voucher box to continue.</p>
            <button className="ticket-button block-btn" onClick={this.handleSubmitError}>Make Payment</button>
        </div>
        )

        let fullDiscountButton = <button disabled={this.state.disableComplete} className="ticket-button block-btn" onClick={this.handleFullPaymentVoucher}>Complete</button>
        // console.log("voucher", this.props.voucher)
        return (
            <div style={{ width: '100%' }}>
                {this.props.voucher != "" && (!this.props.isVoucherApplied) ? errorVoucher : (this.state.showModal && this.props.agree2terms) || this.state.allFilled ?
                    this.props.amount == 0 && this.props.isVoucherApplied ?
                        fullDiscountButton:
                        (<div>
                                {!this.state.showPayments ? (<div>
                                    <button className="ticket-button block-btn" onClick={async () => {
                                        let r = await this.checkAll()
                                        console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww", this.state.toSend)
                                        if(r.status){
                                            this.setState({showPayments: true}) 
                                        }else{
                                            swal('Failed to complete transactions');
                                        }

                                    }}>Make Payment</button>
                                </div>) 
                                :
                                (<div>




                               <PaymentPaystack className="ticket-button block-btn"
                                    userDetails={this.props.userDetails}
                                    ticketInfo={this.props.ticketInfo} 
                                    amount={this.props.amount}
                                    totalTickets={this.props.totalTickets}
                                    cinemaId={this.props.cinemaId}
                                    amountBeforeVoucher={this.props.amountBeforeVoucher}
                                    filmHouseId={this.props.filmHouseId}
                                    genesisId={this.props.genesisId}
                                    selectedCinema={this.props.selectedCinema}
                                    selectedTickets={this.props.selectedTickets}
                                    selectedShowtime={this.props.selectedShowtime}
                                    movie={this.props.movie}
                                    carType={this.props.carType}
                                    isVoucherApplied={this.props.isVoucherApplied}
                                    voucher={this.props.voucher}
                                    toSend={this.state.toSend}
                                />
                                


                                 {/*<Payment className="ticket-button block-btn"
                                    userDetails={this.props.userDetails}
                                    ticketInfo={this.props.ticketInfo} 
                                    amount={this.props.amount}
                                    totalTickets={this.props.totalTickets}
                                    cinemaId={this.props.cinemaId}
                                    amountBeforeVoucher={this.props.amountBeforeVoucher}
                                    filmHouseId={this.props.filmHouseId}
                                    genesisId={this.props.genesisId}
                                    selectedCinema={this.props.selectedCinema}
                                    selectedTickets={this.props.selectedTickets}
                                    selectedShowtime={this.props.selectedShowtime}
                                    movie={this.props.movie}
                                    carType={this.props.carType}
                                    isVoucherApplied={this.props.isVoucherApplied}
                                    voucher={this.props.voucher}
                                    toSend={this.state.toSend}
                                />*/}
                                </div>) }
                        </div>) : readyButton
                }
            </div>);
    }
}

function mapStateToProps(state, ownProps) {

    let { movie } = state.movie
    let { cinemas } = movie;
    let { cinemaId } = ownProps;

    let selectedCinemaArr = cinemas.filter((item, index) => {
        return item.id === cinemaId;
    })

    let selectedCinema = selectedCinemaArr[0];

    return {
        selectedCinema, movie
    }
}

export default connect(mapStateToProps)(RavePay);
