export function slugify(text) {
    return text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
}

export function formatDate(date) {
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    let dayFigure = (date.getDate() <= 9) ? `0${date.getDate()}` : date.getDate();
    let theMonth = date.getMonth() + 1;
    //('theMonth: ', theMonth);

    let monthFigure = theMonth >= 10 ? theMonth : '0' + theMonth;

    let toReturn = `${days[date.getDay()]},${dayFigure}/${monthFigure}/${date.getFullYear()}`
    return toReturn;
}

export function formatTime(time) {
    return time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
}

export function uniques(arr) {

    let unique = arr.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
    return unique;
}

export function uniqueObjects(arr, comp) {

    const unique = arr
        .map(e => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

    return unique;
}