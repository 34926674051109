import React, { Component } from "react";
import MyAutoSuggest from "../../helperComponents/AutoSuggest";
import { Link } from "react-router-dom";
import { format } from 'date-fns'
import swal from 'sweetalert';

import { getUserFromLocal } from '../../subscription/localUser';
export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      user: null,
    }
  }
  componentDidMount() {
    this.props.getMovies();

    let userLocal = getUserFromLocal();

    if (userLocal && userLocal.token) {
      let { token } = userLocal;
      this.setState({ token: token, user: userLocal.user });
      this.props.user(token)
    }
  }
  clearLocalStorage = () => {
    localStorage.removeItem('moviesubscription:token');
    localStorage.removeItem('moviesubscription:user');

  }
  handleLogout = (e) => {
    e.preventDefault();
    this.clearLocalStorage();
    this.props.clearUser();
  }
  handleCancelSub = (e) => {
    e.preventDefault();
    this.props.unsubscribe(this.state.token)
  }
  handleResubscribe = (e) => {
    e.preventDefault();
    this.props.resubscribe(this.state.token);

  }
  displayAlert = (status) => {
    swal(` ${status} succesfully !`, "", "success")

  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subscription.subStatus !== this.props.subscription.subStatus) {
      let prev = prevProps.subscription.subStatus;
      let new1 = this.props.subscription.subStatus;
      //console.log(`prev: ${prev}, new: ${new1}`);
      this.displayAlert(new1)
    }
  }
  render() {
    let { movies, location, userFresh } = this.props;

    let user = userFresh ? userFresh :
      ((this.state.user && this.state.user.token) ? this.state.user : null);



    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="../assets/images/nairabox_white.png"
              width="163px"
              height="50px"
              alt="Nairabox Logo"
            />
          </a>

          <div class="nav__search">
            <i class="fa fa-search icon" />
            {location === "/" && (
              <MyAutoSuggest
                data={movies}
                name="search"
                placeholder="Search for movie"
                id=""
              />
            )}
            {/* <input type="text" class="nav__search-input" name="search" placeholder="Search for movie" id="" /> */}
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse nav-menu"
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="/movies">
                  Movies
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/">
                  Cinemas
                </a>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={{ pathname: "/moviesubscription" }}
                >
                  Movie Subscription
                </Link>
              </li>
              {
                user ? (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#/some"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="../assets/images/icons/ticket-icon.svg"
                        alt="ticket icon"
                      />
                      <p className="menu-ticket__count">
                        {
                          (user.movies_subscription.status === 'inactive') ?
                            '' :
                            (user.movies_subscription.status === 'active') &&
                            <><span>You have </span> <span>
                              {user.movies_subscription.tickets} tickets</span>
                            </>
                        }

                      </p>
                      <img
                        src="../assets/images/icons/person-icon.svg"
                        className=" dropdown-toggle"
                        alt="person icon"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      />
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <p className="dropdown-item" href="#/some">
                        <span>Hi {user.first_name},</span>
                        {
                          (user.movies_subscription.status === 'inactive') ?
                            '' :
                            (user.movies_subscription.status === 'active') &&
                            <><span>Your subscription will be ending {format(user.movies_subscription.end_date, 'DD/MM/YYYY')} </span>
                            </>

                          // <>
                          //   <span> Your subscription will be ending <b>31/08/2019.</b></span>
                          // </>
                        }

                      </p>
                      <div className="dropdown-divider" />

                      {
                        (user.movies_subscription.status === 'inactive') ?
                          '' :
                          (user.movies_subscription.status === 'cancelled') ?

                            (<>
                              <a className="dropdown-item pt-3 grey2"
                                onClick={this.handleResubscribe}
                                href="/#">
                                Re-activate Subscription
                            </a>
                            </>) :

                            (<>
                              <a className="dropdown-item pt-3 grey2"
                                onClick={this.handleCancelSub}
                                href="/#">
                                Cancel Subscription
                            </a>
                            </>)
                      }




                      <a className="dropdown-item red pt-3"
                        onClick={this.handleLogout}
                        href="#/some">
                        Logout
                    </a>
                    </div>
                  </li>
                ) :
                  (
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={{ pathname: "/login" }}
                      >
                        Login
                  </Link>
                    </li>
                  )

              }

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#/some"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="../assets/images/icons/ticket-icon.svg"
                    alt="ticket icon"
                  />
                  <p className="menu-ticket__count">
                    <span>You have </span> <span>2 tickets</span>
                  </p>
                  <img
                    src="../assets/images/icons/person-icon.svg"
                    className=" dropdown-toggle"
                    alt="person icon"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <p className="dropdown-item" href="#/some">
                    <span>Hi Ifeanyi,</span>
                    Your subscription will be ending <b>31/08/2019.</b>
                  </p>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item pt-3 grey2" href="#/some">
                    Cancel Subscription
                  </a>

                  <a className="dropdown-item red pt-3" href="#/some">
                    Logout
                  </a>
                </div>
              </li> */}

              {/* <div className="menu-ticket">
                <p><img src="../assets/images/icons/ticket-icon.svg" alt="ticket icon" /></p>
                <p className="menu-ticket__count"><span>You have </span> <span>2 tickets</span></p>
                <div className="dropdown menu-ticket__person">
                  <img src="../assets/images/icons/person-icon.svg" className=" dropdown-toggle" alt="person icon" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                  <div className="dropdown-menu dropdown-menu-right menu-ticket__dropdown" aria-labelledby="dropdownMenu2">
                    <p className="dropdown-item">Hi Ifeanyi,<br/>
                      Your subscription will be ending 31/08/2019.
                    </p>
                    <p className="dropdown-item">Cancel Subscription</p>
                    <p className="dropdown-item">Logout</p>
                  </div>
                </div>
              </div> */}
              {/* <li className="nav-item">
                  <a className="nav-link" href="/">Cinemas</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">Login</a>
                </li> */}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
