import axios from 'axios';
import globals from '../../globals';
import { uniqueObjects } from '../../helpers';
import { GET_CINEMAS_PENDING, GET_CINEMAS_SUCCESS, GET_CINEMAS_FAILED, LOADING_START, LOADING_END } from './types'

//https://movies-apis.nairabox.net/v2/tickets?as=filterByCinema&cinema=2019-06-11T09:30:00.0Z
let as = 'cinemas';
let moviesPath = `${global.nairaboxENV(1)}/v2/tickets?&as=${as}`;

export function getCinemas() {
    return async (dispatch) => {

        dispatch({ type: LOADING_START, loadingText: ' ... loading cinemas' })
        dispatch({ type: GET_CINEMAS_PENDING });

        try {
            let dresponse = await fetch(moviesPath);
            let resJson = await dresponse.json();
            console.log('cinemas all: ', resJson);

            dispatch({ type: LOADING_END })
            dispatch({ type: GET_CINEMAS_SUCCESS, payload: resJson.cinemas });

        } catch (error) {
            console.log('error in moviesfilter: ', error);
            dispatch({ type: LOADING_END })
            dispatch({ type: GET_CINEMAS_FAILED, payload: error });
        }
    }
}

