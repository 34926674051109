// impov2/tickets?as=the-legend-of-inikpi

import React, { Component } from 'react';

export default class MovieStatus extends Component {
    constructor(props){
        super(props);
        this.state = {
            result: {}
        }
    }

    async componentDidMount(){
        let as = "the-legend-of-inikpi";
        let url = `${global.nairaboxENV(1)}/v2/tickets?&as=${as}`;
        try {
            let data = await fetch(url);
            let json = await data.json();
            console.log(json)
            this.setState({ result: json }) 
        } catch (error) {
            this.setState({ result: "Error occurred while fetching stats"})
        }
    }

    render(){
        return(`${JSON.stringify(this.state.result)}`)
    }
}