import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMovies } from '../redux/actions/movies';
import { user, clearUser, unsubscribe, resubscribe } from '../redux/actions/user'

import Nav from '../containers/includes/NavSearch';

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ getMovies, user, clearUser, unsubscribe, resubscribe }, dispatch)
}


const mapStateToProps = (state, ownProps) => {
    let theMovies = state.movies.movies;
    let user = state.user.user;
    return {
        movies: theMovies,
        userFresh: user,
        subscription: state.user,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Nav)