import React from 'react';
import RavePaymentModal from 'react-ravepayment';
import swal from 'sweetalert';
import globals from '../globals';
import axios from 'axios';

class Payment extends React.Component {

	close = () => {
		console.log("Payment closed");
		console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",this.state)
	}

	state = {
		key: global.nairaboxENV() == 'local' || global.nairaboxENV() == 'heroku' ? global.nairaboxENV("raveTest") : global.nairaboxENV("raveLive"),
		//key: globals.raveTest || raveLive,
		email: this.props.toSend ? this.props.toSend.userDetails.email : "",
		amount: this.props.toSend ? this.props.toSend.amount : 0, // equals NGN 1000. Minimum amount allowed NGN 1 while on production or live system, it's 10
		metadata: [],
		phone: '',
		fullname: '',
		amount: 0,
		email: ''
	};

	componentDidMount() {
		console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx",this.props.toSend)
		// const { amount, movieId, selectedCinema, movie, amountBeforeVoucher } = this.props;
		// const { id, name, location_id, cinemaChainId, filmHouseId } = selectedCinema;
		// const userDetails = this.props.userDetails && this.props.userDetails[0];

		// const users = this.props.userDetails && JSON.stringify(this.props.userDetails)
		// const ticketInfo = this.props.ticketInfo && JSON.stringify(this.props.ticketInfo)
		// const totalTickets = this.props.totalTickets && JSON.stringify(this.props.totalTickets)

		// console.log('componentDidMount***Payment', this.props)

		// this.setState({
		// 	metadata: [
		// 		{ metaname: "firstname", metavalue: userDetails && userDetails.fullname },
		// 		{ metaname: "amount", metavalue: amount },
		// 		{ metaname: "amountBeforeVoucher", metavalue: amountBeforeVoucher },
		// 		{ metaname: 'userDetails', metavalue: users },
		// 		{ metaname: 'ticketInfo', metavalue: ticketInfo },
		// 		{ metaname: 'totalTickets', metavalue: totalTickets },
		// 		{ metaname: 'voucher', metavalue: this.props.voucher || null },
		// 		{ metaname: 'carType', metavalue: this.props.carType || null }
		// 	], phone: userDetails && userDetails.phone,
		// 	fullname: userDetails && userDetails.fullname,
		// 	amount: amount,
		// 	email: userDetails && userDetails.email
		// });

	}

	callback = async (response) => {
      
		if (response.tx.chargeResponseCode == "00" || response.tx.chargeResponseCode == "0") {
			// Set custom meta information
			//response.tx.toSend = this.props.toSend || {};

			let rsend = {txRef: response.tx.txRef, toSend: this.props.toSend}


			// verify transaction
			axios.post(`${global.nairaboxENV(1)}/v2/tickets?as=verify-website`, rsend, {headers: {'verif-hash': global.nairaboxENV("HASH")}})
			.then(resp => {
				window.location.href = resp.data.url;
				console.log("++++++++++++++++++++++++++++++++++++", resp.data);
				swal('Ticket Purchase Succesful, Please check your mail for your E-ticket and close this prompt to download ticket')
			}).catch(err => {
				console.log("++++++++++++++++++++++++++++++++++++++++++++++", err.message);
				if (err.response && err.response.data && err.response.data.message) {

					swal('Ticket purchase  Failed:- ' + err.response.data.message)
				} else {
					swal('Ticket purchase  Failed:- ' + err.message)
				}

			});


		} else {
			swal('Failed to complete transactions');
		}
	};

	getReference = () => {
		let text = '';
		let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < 40; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	};



	render() {
		return (
			<div className="App">
				<p className="App-intro">
					<RavePaymentModal
						text="Make Payment with flutterwave"
						class="ticket-button block-btn"
						// meta data to be passed along to Rave.


						metadata={this.state.toSend}
						// Transaction Reference. The getReference generates random characters to be used as the transaction ref.
						reference={this.getReference()}
						// Customer's email
						email={this.props.toSend ? this.props.toSend.userDetails.email : ""}
						// Amount to be charged
						//campaign_id={global.nairaboxENV() == 'local' || global.nairaboxENV() == 'heroku' ? "NAIRABOX_STAGING" : "NAIRABOX_CINEMA"}
						amount={this.props.toSend.amount}
						customer_firstname={this.props.toSend ? this.props.toSend.userDetails.fullname : ""}
						customer_phone={this.props.toSend ? this.props.toSend.userDetails.phone: ""}
						// Your Rave Public Key
						ravePubKey={this.state.key}
						// The callback function after a successful payment. The function was created above
						callback={this.callback}
						// The close function after closing the modal. The function was created above
						close={this.close}
						isProduction={global.nairaboxENV() == 'local' || global.nairaboxENV() == 'heroku' ? false : true}
					/>
				</p>
			</div>
		);
	}
}

export default Payment;
