import React, { Component } from 'react';
import axios from 'axios';
//import Payment from '../components/payment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import sweetAlert from 'sweetalert';
//import { user, clearUser, unsubscribe, resubscribe } from '../redux/actions/user'
import { user } from '../redux/actions/user';
import { getUserFromLocal } from '../subscription/localUser';


import RavePay from '../components/RavePay';
import PaymentSub from '../subscription/Payment';
import { Link } from 'react-router-dom';
//showing in BuyTicketDetails
class TicketDetailsSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agree2terms: false,
			token: '',
			voucher: '',
			newqty: 0,
			newprice: 0,
			disabled: false,
			isVoucherApplied: false,
			isFixedFeeVoucherApplied: false,
			discount: {}
		}
		this.myRef = React.createRef();
	}
	
	componentDidMount() {
		console.log("This ", this.props)
		let userLocal = getUserFromLocal();

		if (userLocal && userLocal.token) {
			let { token } = userLocal;
			this.setState({ token: token });
			this.props.userAction(token)
		}
	}
	
	doRevert = () => {
		console.log('my ref...: ', this.myRef);
		this.myRef.current.checked = false;
		this.setState({ agree2terms: false });
	}

	handleVoucherInputChange = (event) => {

	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name]: value });
	}

	handleSubmitVoucher = async (event) => {
		let { selectedTickets, price, cinemaId, filmHouseId, genesisId, movie, user } = this.props;
		event.preventDefault()
		console.log('with voucher....', this.props);
		let toSend = { voucher: this.state.voucher, ...this.props }
		this.setState({ disabled: true })
		let _this = this;
		let res;

		try {
			// https://movies-apis.nairabox.net/v2/tickets/?as=voucher
			let response = await axios.post(`${global.nairaboxENV(1)}/v2/tickets/?as=voucher`, toSend)
			console.log("+++++++++++++++++++++++", response.data.message)

			const dateTime = '2017-02-04 11:23:54';
			console.log(this.getTimeAndDate(dateTime))
			var today = new Date();
			var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
			var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
			var date_Time = date + ' ' + time;
			var d = new Date();
			var today = d.getDay()

			var fixed_fee = response.data.voucher.promofee
			var dayofweek = response.data.voucher.dayofweek

			if (fixed_fee && dayofweek) {

				var presenttime = this.getTimeAndDate(date_Time)
				var endtime = this.getTimeAndDate(response.data.voucher.expires)
				var starttime = this.getTimeAndDate(response.data.voucher.starttime)

				console.log(starttime, endtime, presenttime)
				if (this.timeCompare(starttime, endtime, presenttime) && today == dayofweek) {
					var selectedTick = selectedTickets
					for (var x = 0; x < selectedTick.length; x++) {
						var qty = selectedTick[x].quantity;
						this.setState({ newqty: qty, newprice: fixed_fee * qty })
						console.log(this.state.newprice, this.state.newqty)
					}
					_this.setState({ isFixedFeeVoucherApplied: true })

					sweetAlert("Success!", response.data.message, "success")
				} else {
					sweetAlert("warning!", "Invalid time or date for promotion, Please try again later!", "warning")
					return;
				}
			} else {
				_this.setState({ isVoucherApplied: true, discount: response.data.voucher })

				sweetAlert("Success!", response.data.message, "success")
			}


		} catch (e) {
			console.log('error message: ', e.message)
			if (e.response && e.response.data && e.response.data.message) {
				sweetAlert("Error!", e.response.data.message, "error");
			} else {
				sweetAlert("Purchase failed!", `Message: ${e.message}`, "error");
			}
		}

	}

	getTimeAndDate = (dateTime) => {
		var myDate = new Date(dateTime);

		var minutes = myDate.getMinutes();
		var hours = myDate.getHours();
		var str_time = hours + ":" + minutes + ":00"
		return str_time
	}

	timeToNumber = (timeString) => {
		var t = timeString.split(" ");

		var offset = 0;

		if (t[1] == "PM") {
			offset = 12;
		}

		var hm = t[0].split(":");

		var hours = Number(hm[0]) + offset;

		return hours * 100 + Number(hm[1]);
	}

	timeCompare = (min, max, ref) => {
		return (this.timeToNumber(min) <= this.timeToNumber(ref)) && (this.timeToNumber(ref) <= this.timeToNumber(max));
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({ [name]: value });
		console.log("state", this.state)
	}

	payWithSub = (e) => {
		let { user } = this.props
		e.preventDefault();
		if (user.movies_subscription && user.movies_subscription.tickets > 0) {

		}
		else {
			if (user.movies_subscription && user.movies_subscription.status === 'active') {
				alert('Sorry, you have ' + user.movies_subscription.tickets + ' tickets left')
			}
		}

	}

	// handleBeforePay = (e) => {
	//   let people = this.props.formValues;
	// }

	render() {
		let { selectedTickets, price, cinemaId, filmHouseId, genesisId, movie, user, ticketFormState } = this.props;

		console.log("OOOOOOOOOOOOOOOOO", ticketFormState)
		let { isVoucherApplied, isFixedFeeVoucherApplied, voucher, discount } = this.state;

		let toReturn = selectedTickets.map((item, key) => {
			return (
				<div className="input-group" key={key}>
					<div className="ticket__summary-table ticket-type_name">
						<div className="table_col_one capitalize">{item.type}</div>
						<div className="table_col_two">{this.state.newqty ? this.state.newqty : item.quantity}</div>
						<div className="table_col_three">₦{this.state.newprice ? this.state.newprice : item.price}</div>
					</div>
				</div>

			)
		});

		return (
			<div className="col-md-4">
				<div className="movie__ticket-summary">
					<div className="input-group">
						{/* <p>Ticket Order Summary</p> */}
					</div>

					<div className="input-group">
						<div className="ticket__summary-table table-heading ticket-type_name">
							<div className="table_col_one capitalize">Ticket Type</div>
							<div className="table_col_two">Qty</div>
							<div className="table_col_three">Amount</div>
						</div>
					</div>

					{toReturn}

					{/* {isFixedFeeVoucherApplied ? <span className="text-danger">NairaBox Bonanza Voucher Applied</span> : null} */}

					{
						isVoucherApplied ?
							discount.isPercentBased ? 
								(<div className="input-group mt-5">
									<div className="ticket__summary-table ticket-type_name font-weight-bold">
										<div className="table_col_one capitalize">Discount ({discount.for})</div>
										<div className="table_col_two">1</div>
										<div className="table_col_three">-{discount.value}%</div>
									</div>
								</div>) :
								(<div className="input-group mt-5">
									<div className="ticket__summary-table ticket-type_name font-weight-bold">
										<div className="table_col_one capitalize">Discount ({discount.for})</div>
										<div className="table_col_two">1</div>
										<div className="table_col_three">-₦{discount.value}</div>
									</div>
								</div>) 
							: null
					}

					<div className="input-group movie__total">
						<span className="total">Total</span>
						<span className="price"><span className="naira_sign">₦</span>
							{isFixedFeeVoucherApplied ? this.state.newprice :
								(isVoucherApplied ? 
									discount.isPercentBased ?
										((discount.value / 100) * price)
									: price - Number(discount.balance) > 0 
											? price - Number(discount.balance) 
										: 0 
								: price)
							}
						</span>
					</div>


					<div className="form-group voucher mb-2">
						<label htmlFor="voucher">Enter Voucher Code(Optional)</label>
						<input type="text" className="form-control" id="voucher" name="voucher" placeholder=""
							value={this.state.voucher}
							onChange={this.handleChange}
							disabled={isVoucherApplied || isFixedFeeVoucherApplied}
						/>
					</div>

					{
						voucher ?
							isVoucherApplied || isFixedFeeVoucherApplied ?
								(<p className="mb-4 voucher-msg">Voucher code applied</p>) :
								(<button className="voucher-btn mb-4" onClick={this.handleSubmitVoucher}>Apply voucher</button>) :
							// (<button className="ticket-button block-btn" onClick={this.handleSubmitVoucher} disabled={this.state.disabled}>Pay With Voucher</button>) :
							(null)
					}

					<div style={{ clear: "both" }}></div>
						<div className="form-check">
						<input className="form-check-input"
							type="checkbox"
							ref={this.myRef}
							name="agree2terms"
							value={this.state.agree2terms}
							onChange={this.handleInputChange}
							id="agree2terms"
						/>

						<label className="form-check-label small" htmlFor="agree2terms">
							&nbsp;I accept the terms and conditions for using this service, and hereby confirm I have read the
				<Link style={{ color: 'green' }} to="/drive-in-terms"> privacy policy. </Link>
						</label>
					</div>
					 


					<div className="input-group">
						{
							(user && user.email && user.movies_subscription.status === 'active') && (
								<div className="col-md-12 login__form-input">
									<button className="ticket-button block-btn" onClick={this.payWithSub}>Pay With Subscription </button>
								</div>
							)
						}

						{console.log('this.state.agree2terms', this.state.agree2terms)}

						<RavePay className="ticket-button block-btn"
							userDetails={this.props.formValues}
							ticketInfo={this.props.ticketInfo}
							amount={
								isFixedFeeVoucherApplied ? 
									this.state.newprice : 
									isVoucherApplied ? 
										discount.isPercentBased ?
											((discount.value / 100) * price)
										: price - Number(discount.balance) > 0 ? 
											price - Number(discount.balance) 
									: 0 
								: price
							}
							amountBeforeVoucher={price}
							isVoucherApplied={isVoucherApplied || isFixedFeeVoucherApplied}
							voucher={voucher}
							totalTickets={this.props.totalTickets}
							showMultiple={this.props.showMultiple}
							agree2terms={this.state.agree2terms}
							cinemaId={cinemaId}
							filmHouseId={filmHouseId}
							genesisId={genesisId}
							movie={movie}
							selectedTickets={this.props.selectedTickets}
							revertAgree={this.doRevert}
							carType={this.props.carType}
							selectedShowtime={this.props.selectedShowtime}
						//ticketFormState4rmBuyTicketForm={this.props.ticketFormState4rmBuyTicketForm}
						/>


					</div>
				</div>
			</div>
		)
	}
}


const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators({ userAction: user }, dispatch)
}

const mapStateToProps = (state, ownProps) => {
	let user = state.user.user;
	return {
		user
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketDetailsSummary)