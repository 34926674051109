import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { slugify } from '../helpers';
import TextTruncate from 'react-text-truncate';

export default class MovieItem extends Component {
    render() {
        // //('this.props.movie in movieItem: ', this.props.movie)
        // //('this.props in movieItem: ', this.props)
        const { cardImage, title, genre, description, duration, ageRestriction, id, _id, starring, name } = this.props.movie;
        let {selectedStateAndLocation} = this.props;
        return (
            <div className="col-12 col-md-6 col-lg-4">
                <div className="movies__card">
                    <div className="movies__card-image" >
                        <Link
                            to={{
                                // pathname: `/movies/${slugify(title)}`,
                                pathname: `/movies/${id}`,
                                state: { id, title, description, selectedStateAndLocation}
                            }}
                        ><img src={cardImage} alt={title ? title.toLowerCase() : name.toLowerCase()} /></Link>

                    </div>
                    <div className="movies__card-details">
                        <div className="movie-name">
                            <TextTruncate
                                line={2}
                                // truncateText="…"
                                text={title ? title.toLowerCase() : name.toLowerCase()}

                            />
                        </div>
                        <div className="movie-genre">Genre: {genre}</div>
                        <p className="movie-showing">
                            Rated {ageRestriction}<br />
                            Running Time: {duration}
                        </p>
                        <div className="movie-cast">
                            <TextTruncate
                                line={3}
                                // truncateText="…"
                                text={`Starring: ${starring}`}

                            />
                        </div>
                        <div>
                            <Link className="btn ticket-button"
                                to={{
                                    // pathname: `/movies/${slugify(title)}`,
                                    pathname: id ? `/movies/${id}` : `/movies/${_id}`,
                                    state: { id, title, description, selectedStateAndLocation }
                                }}
                            >Buy Ticket</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}