import axios from 'axios';
import globals from '../../globals';
import {
    USER_PENDING, USER_SUCCESS, USER_FAILED,
    USER_UNSUBSCRIBE_SUCCESS, USER_UNSUBSCRIBE_FAILED,
    USER_RESUBSCRIBE_SUCCESS, USER_RESUBSCRIBE_FAILED,
    LOADING_START, LOADING_END, USER_CLEAR, USER_SUBFAILURE
} from '../actions/types';


//const authUrl = `${globals.api}${authPath}`;
const authUrl2 = `https://movie-visa.herokuapp.com/api/v1/user`;
const authPath = '/user';
const visaApi = `${process.env.REACT_APP_VISA_API}`
const authUrl = `${visaApi}${authPath}`;
///api/v1/user/unsubscribe
// resubscribe

export function user(token) {
    return async (dispatch) => {
        dispatch({ type: USER_PENDING });
        dispatch({ type: LOADING_START, loadingText: ' ... refreshing user details ... ' })
        let res;
        try {
            res = await axios({ method: 'get', url: authUrl, headers: { 'Authorization': `bearer ${token}` } })

            dispatch({ type: LOADING_END })
            dispatch({ type: USER_SUCCESS, payload: res.data });
            localStorage.setItem('moviesubscription:user', JSON.stringify(res.data.user));

        } catch (error) {
            //console.log('Problem refreshing user. Error: ', error);
            dispatch({ type: LOADING_END })
            dispatch({ type: USER_FAILED, payload: error });

        }
    }
}
export function clearUser() {
    return { type: USER_CLEAR }
}

export function unsubscribe(token) {
    return async (dispatch) => {
        dispatch({ type: LOADING_START, loadingText: ' ... unsubscribing ... ' })
        let res;
        try {
            res = await axios({
                method: 'get', url: authUrl + '/unsubscribe',
                headers: { 'Authorization': `bearer ${token}` }
            })
            //console.log('successfully unsubscribed: ', res);

            dispatch({ type: LOADING_END })
            dispatch({ type: USER_UNSUBSCRIBE_SUCCESS, payload: res.data.user });
            localStorage.setItem('moviesubscription:user', JSON.stringify(res.data.user));

        } catch (error) {
            //console.log('Problem unsubscribing user. Error: ', error);
            dispatch({ type: LOADING_END })
            dispatch({ type: USER_UNSUBSCRIBE_FAILED, payload: error });
            dispatch({ type: USER_SUBFAILURE, payload: error.message })
        }
    }
}

export function resubscribe(token) {
    return async (dispatch) => {
        dispatch({ type: LOADING_START, loadingText: ' ... resubscribe  ... ' })
        let res;
        try {
            res = await axios({
                method: 'get', url: authUrl + '/resubscribe',
                headers: { 'Authorization': `bearer ${token}` }
            })

            dispatch({ type: LOADING_END })
            dispatch({ type: USER_RESUBSCRIBE_SUCCESS, payload: res.data.user });
            localStorage.setItem('moviesubscription:user', JSON.stringify(res.data.user));

        } catch (error) {
            dispatch({ type: LOADING_END })
            dispatch({ type: USER_RESUBSCRIBE_FAILED, payload: error });
            dispatch({ type: USER_SUBFAILURE, payload: error.message })
        }
    }
}