import axios from 'axios';
import globals from '../../globals';
import {
    GET_SOLDOUT_PENDING, LOADING_START, LOADING_END,
    GET_SOLDOUT_SUCCESS, GET_SOLDOUT_FAILED,
} from '../actions/types'

let soldoutPath = `${global.nairaboxENV(1)}/api/cinemas/displaySiteShowtime`;
//    /api/cinemas/displaySiteShowtime/3f2f862b-2702-4ea3-4385-08d5c686e473


export function getSoldout(siteId) {

    console.log(`about to get sold out: ${soldoutPath}/${siteId}`);
    return (dispatch) => {
        dispatch({ type: LOADING_START, loadingText: ' ... checking for available seats' })
        dispatch({ type: GET_SOLDOUT_PENDING });
        return axios.get(`${soldoutPath}/${siteId}`)
            .then(function (response) {
                dispatch({ type: LOADING_END })
                console.log('soldout response: ', response)
                dispatch({ type: GET_SOLDOUT_SUCCESS, payload: response.data });
            })
            .catch(function (error) {
                dispatch({ type: LOADING_END })
                console.log('error getting soldout: ', error)
                dispatch({ type: GET_SOLDOUT_FAILED, payload: error });
            });
    }
}
