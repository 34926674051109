import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';

import { getShowTimes } from "../redux/actions/showtime";
import { getSoldout } from "../redux/actions/soldout";
import { formatDate, formatTime, uniques, uniqueObjects } from "../helpers";
import DriveInModal from "./DriveInModal";
import { carTypes } from "../utils/car-type";
import swal from 'sweetalert';


class BuyTicketForm extends Component {
  constructor(props) {
    super(props)
    let { selectedStateAndLocation } = this.props.location.state? this.props.location.state : "";

    this.state = {
      location: selectedStateAndLocation ? selectedStateAndLocation.location : 0,
      locations: [],
      locationsObjs: [],
      cities: [],
      city: "",
      state: selectedStateAndLocation ? selectedStateAndLocation.state : 0,
      states: [],
      date: 0,
      time: 0,
      selectedCinemaId: "",
      ticketType: "",
      ticketType_1: "",
      ticketType_2: "",
      ticketType_3: "",
      ticketTypes: {},
      price: 0,
      selectedTicketTypesWithQty: [], //[{'ticketType_1': 2}, {'ticketType_2: 1}]
      movieType: "2D",
      selectedShowTimeID: "",
      selectedMongoDate: "",
      showtimes: [],

      datesWithShowtimeIds: [],
      filmhouseSiteId: "",
      selectedShowtime: null,
      formValues: null,
      formSubmitted: false,
      soldoutStatus: false,
      filmhouseShowtimeId: "",
      seatsAvailable: 0,
      isSoldOut: false,
      totalChosenTickets: 0,
      showDateandTime: false,
      show: true,
      carType: "",

      customerEmail: "",
      customerPhone: "",
      emailSubmitable: false
    };

    this.ticketTypeRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTicketType = this.handleTicketType.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleEmailFormSubmit = this.handleEmailFormSubmit.bind(this);
    this.emailInputChange = this.emailInputChange.bind(this);
    this.phoneInputChange = this.phoneInputChange.bind(this);
    this.getCities = this.getCities.bind(this);
    this.getStates = this.getStates.bind(this);
    this.getLocationOptions = this.getLocationOptions.bind(this);
    this.refreshState = this.refreshState.bind(this);


  }

  handleTicketType(price, ticketTypeId, event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });

    if (name.startsWith("ticketType")) {
      let theName = name.split("_")[1];

      //if there is an object in the array with key adult already
      //go and replace it instead of concatinating to the array
      let selected = this.state.selectedTicketTypesWithQty;
      let findit = selected.filter((item) => {
        return item.type !== theName;
			});

      this.setState({
        selectedTicketTypesWithQty: findit.concat({
          type: theName,
          quantity: value,
          price: price,
          ticketTypeId: ticketTypeId,
        }),
      });
    }
  }

  refreshState(stateToRefresh) {
    this.setState(stateToRefresh);
  }

  emailInputChange(e) {
    this.setState({customerEmail: e.target.value})
  }

  phoneInputChange(e) {
    this.setState({customerPhone: e.target.value})
  }

  handleInputChange(event, fromFilter, selectedCinema, dmovie) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });

    let { movieId, movie } = this.props;
    if (name === "state" || event.fromFilter) {
      this.getCinemasInState(value);
      this.refreshState({
        location: 0,
        date: 0,
        time: 0,
        price: 0,
        selectedMongoDate: "",
        selectedShowTimeID: "",
        showtimes: [],
        selectedTicketTypesWithQty: [],
        filmhouseSiteId: "",
				filmhouseShowtimeId: "",
				carType: "",
      });
    }

    if (name === "location") {
      let { locationsObjs } = this.state;
      let selected;
      let theMovieId;
      let filmhouseSiteId;

      this.refreshState({
        date: 0,
        time: 0,
        price: 0,
        selectedMongoDate: "",
        selectedShowTimeID: "",
        selectedTicketTypesWithQty: [],
        filmhouseSiteId: "",
				filmhouseShowtimeId: "",
				carType: "",
      });

      if (fromFilter) {
        selected = selectedCinema ? selectedCinema : "";
        filmhouseSiteId = selected.siteId;
        theMovieId = fromFilter;
        //this.getShowtimes(value, fromFilter);

        if (filmhouseSiteId) {
          console.log(`${value}, ${theMovieId}, ${filmhouseSiteId}, ${dmovie.filmhouse_id}`)
          this.setState({filmhouseSiteId: filmhouseSiteId, filmhouseShowtimeId: dmovie.filmhouse_id});
          this.getShowtimes(value, theMovieId);
        } else {
          this.getShowtimes(value, theMovieId);
        }
      } else {
        selected = locationsObjs.filter((item, index) => item.id === value)[0];
        filmhouseSiteId = selected ? selected.siteId : "";

        if (filmhouseSiteId) {
          this.setState({ filmhouseSiteId: filmhouseSiteId });
          this.props.getShowTimes(value, movieId, filmhouseSiteId, movie.filmhouse_id);
        } else {
          this.getShowtimes(value, movieId);
        }
      }
		}

    if (name === "carType") {

			console.log("state changed")

      this.setState({ carType: value, filmhouseSiteId: "",	filmhouseShowtimeId: "" });
      this.forceUpdate()
      this.state.carType = value

    }

    if (name === "ticketType") {
    }

    if (name === "time") {
      let timeShowtimeId = event.target[
        event.target.selectedIndex
      ].getAttribute("data-showtimeid");
      let timeShowtimeDate = event.target[
        event.target.selectedIndex
      ].getAttribute("data-showtimedate");

      let { showtime } = this.props;

      let selectedShowtime = showtime.filter((item) =>
          item._id === timeShowtimeId && item.mongodate === timeShowtimeDate
      )[0];

      console.log("ooooooooooooooooooooooooooooooooooooooooooooooooooooooooo", selectedShowtime)

      let filmhouseShowtimeId = selectedShowtime.id;

      this.setState({
        filmhouseShowtimeId: filmhouseShowtimeId,
        seatsAvailable: selectedShowtime.limit,
        selectedShowtime: selectedShowtime,
        selectedShowTimeID: timeShowtimeId,
        selectedMongoDate: timeShowtimeDate,
      });

      //let { filmhouseSiteId } = this.state;
      // if (filmhouseShowtimeId && filmhouseSiteId) {
      //   this.props.getSoldout(filmhouseSiteId);
      //   //this.getSeatsAvailable(filmhouseSiteId, filmhouseShowtimeId)
      // }

    }

    if (name === "date") {
      let { showDateandTime } = this.state;
      this.refreshState({ time: 0, selectedShowTimeID: "" });
      let selectedMongoDate = event.target[event.target.selectedIndex].getAttribute("data-mongodate");
      this.setState({ selectedMongoDate: selectedMongoDate });
      this.setState({soldoutStatus: "", seatsAvailable: 0, isSoldOut: false});
      if (!showDateandTime) {
        let timeEl = document.getElementsByName("time")[0];

          let firstValue = timeEl.options[1];
          let timeShowtimeId = firstValue.getAttribute("data-showtimeid");
          let timeShowtimeDate = firstValue.getAttribute("data-showtimedate");

          let { showtime } = this.props;

          let selectedShowtime = showtime.filter(
            (item) =>
              item._id === timeShowtimeId && item.mongodate === timeShowtimeDate
          )[0];

          let filmhouseShowtimeId = selectedShowtime.id;

          this.setState({
            filmhouseShowtimeId: filmhouseShowtimeId,
            selectedShowtime: selectedShowtime,
            selectedShowTimeID: timeShowtimeId,
            selectedMongoDate: timeShowtimeDate,
          });

          // let { filmhouseSiteId } = this.state;

          // if (filmhouseShowtimeId && filmhouseSiteId) {
          //   this.props.getSoldout(filmhouseSiteId);
          //   //this.getSeatsAvailable(filmhouseSiteId, filmhouseShowtimeId)
          //}
      }
    }
  }

  handleFormSubmit(e) {
    //let ticketTypeQty = this.ticketTypeRef.current.value;
    let {
      state,
      date,
      location,
      time,
      selectedCinemaId,
      price,
      selectedTicketTypesWithQty,
      selectedMongoDate,
      selectedShowTimeID,
      filmhouseSiteId,
      selectedShowtime,
      carType,
    } = this.state;
    let formValues = {
      date,
      location,
      time,
      selectedCinemaId,
      selectedTicketTypesWithQty,
      selectedMongoDate,
      price,
      selectedShowTimeID,
			selectedShowtime,
      carType,
      
    };
    if (!state && !location && !date && !time ) {
      alert("Please select the state, location, date and time ");
      return;
    }
    if (selectedTicketTypesWithQty.length === 0) {
      // if (showDateandTime) {
      alert("Please select a ticket type.");
      return;
      // }
    }

    if (this.getSoldoutStatus(formValues)) {

    }
      this.props.toParent(true, formValues, this.state, e);
  }

  async handleEmailFormSubmit() {

    if (!this.state.customerEmail) {
      swal('You have to provide your email', "error")
      return;
    }

    this.setState({emailSubmitable: true})

    try{
      
      let sts = this.props.url.split("/")
      let toSubmit = {name: "Movie Customer", phone: this.state.customerPhone, email: this.state.customerEmail, location: "lagos", selected: [sts[2]]}

      let res = await axios.post(`${global.nairaboxENV(1)}/v2/postdetails`, toSubmit)
      console.log("send-customer-details-new-movie-try: ", res.data)
      swal("Good job!", "Information submited successfully!", "success");
    }catch(e){
      let msg = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
      this.setState({emailSubmitable: false})
      swal("Error", msg, "error");
      console.log("send-customer-details-new-movie-catch: ", e)
  }

  }

  getSeatsAvailable = (filmhouseSiteId, filmhouseShowtimeId) => {
    if (filmhouseSiteId) {
      let { soldout } = this.props;

      if (soldout) {
        if (soldout.fetching) {
          this.setState({ soldoutStatus: `... checking available seats ...` });
        }

        if (soldout.error) {
          this.setState({ soldoutStatus: `Couldn't find available seats` });
        }

        if (soldout.soldout && soldout.soldout.constructor === Array) {
          let soldoutArray = soldout.soldout;
          let soldoutObj = soldoutArray.filter((item, index) => {
            return item.id === filmhouseShowtimeId;
          })[0];

          if (soldoutObj) {
            let { seatsAvailable } = soldoutObj;
            //console.log('seatsAvailable value: ', seatsAvailable);
            this.setState({ seatsAvailable: seatsAvailable });
          }
        }
      } else {
        //console.log('no soldout data')
      }
    }
  };

  getSoldoutStatus = (formValues) => {
		let { selectedTicketTypesWithQty, seatsAvailable } = this.state;
		
		console.log('getSoldoutStatus', formValues)

    let totalTickets = selectedTicketTypesWithQty.reduce((total, currItem) => {
      return total + parseInt(currItem.quantity);
    }, 0);

    this.setState({ totalChosenTickets: totalTickets });

    if (parseInt(seatsAvailable) < parseInt(totalTickets)) {
      this.setState({ isSoldOut: true });
      //console.log('so sold out')
      return false;
    } else {
      this.setState({ isSoldOut: false });
      return true
    }
  };

  getShowtimes(cinemaHouseId, movieId) {
    //value, movieId, filmhouseSiteId, movie.filmhouse_id
    this.props.getShowTimes(cinemaHouseId, movieId);
  }

  getMovieDate() {
    let { showtimes } = this.state;

    let datesWithShowtimeIds =
      showtimes &&
      showtimes.map(function(item) {
        return { showtimeId: item["_id"], showtimeDate: item.mongodate };
      });

    this.setState({ datesWithShowtimeIds: datesWithShowtimeIds });
  }

  getCities() {
    let { cinemas } = this.props;
    let cities = [];

    cinemas &&
      cinemas.map((item, index, arr) => {
        if (!cities.includes(item.location)) {
          cities.push(item.location);
        }
        this.setState({ cities: cities });
        return cities;
      });
  }

  getStates() {
 
    let { cinemas } = this.props;
    let states = [];

    cinemas &&
      cinemas.map((item, index, arr) => {
        if (!states.includes(item.state)) {
          states.push(item.state);
        }
      });
      this.setState({ states: states });
      return states;
  }

  getLocationOptions(locations) {
    let locOptions = locations.map(function(item, index, arr) {
      return (
        <option value={item} key={index}>
          {item}
        </option>
      );
    });
    return locOptions;
  }

  getLocationObjOptions(locations) {
    let locOptions = uniques(locations).map(function(item, index, arr) {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });
    return locOptions;
  }

  getCinemasInCity(city) {
    let { cinemas } = this.props;
    let locations = [];

    let optionCinemas =
      cinemas &&
      cinemas.map((item, index, arr) => {
        if (item.location === city) {
          if (!locations.includes(item.name)) {
            locations.push(item.name);
          }
          this.setState({ locations: locations });

          return this.getLocationOptions(locations);
          //return <option value={item.id} key={item.id}>{item.name}</option>
        }
        return null;
      });
    return optionCinemas;
  }

  getCinemasInState(state, cinemasFromFilter) {
    let { cinemas } = this.props;
    let locations = [];
    let locationsObjs = [];
    let theCinemas = cinemasFromFilter ? cinemasFromFilter : cinemas;
    if (theCinemas) {
      let optionCinemas =
        theCinemas &&
        uniques(theCinemas).map((item, index, arr) => {
          if (item.state === state) {
            if (!locations.includes(item.name)) {
              locations.push(item.name);

              locationsObjs.push(item);
            }
            this.setState({ locations: locations });
            this.setState({ locationsObjs: locationsObjs });

            //return this.getLocationObjOptions(locationsObjs);
            return (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            );
          }
          return null;
        });
      return optionCinemas;
    }
  }

  getTimeOptions(fromFilter, selectedDate, dateNshowtimes) {
    let theSelectedDate, showtimesWithDate;
    if (fromFilter) {
      theSelectedDate = selectedDate;
      showtimesWithDate = dateNshowtimes;
    } else {
      theSelectedDate = this.state.selectedMongoDate;
      showtimesWithDate = this.state.datesWithShowtimeIds;
    }

    console.log("ddddd... ", selectedDate, dateNshowtimes, this.state.selectedMongoDate)

    if (!theSelectedDate) return;
    let dateMinusTime = theSelectedDate.split("T")[0];

    let now1 = new Date();
    let showtimeInTheFuture = showtimesWithDate && showtimesWithDate.filter((item) => { return new Date(item.showtimeDate) > now1; });

    let filteredDatesWithShowtimeIds = showtimeInTheFuture && showtimeInTheFuture.filter(function(item, index, arr) { return item.showtimeDate.startsWith(dateMinusTime);});

    //console.log('filteredDatesWithShowtimeIds: ', filteredDatesWithShowtimeIds);

    let theTimes2 = filteredDatesWithShowtimeIds &&
      filteredDatesWithShowtimeIds.map(function(item, index, arr) {
        return (
          <option value={formatTime(new Date(item.showtimeDate))} key={item.showtimeId} data-showtimeid={item.showtimeId} data-showtimedate={item.showtimeDate}>
            {formatTime(new Date(item.showtimeDate))}
          </option>
        );
      });

    return theTimes2;
  }

  getDateOptions(fromLocation) {
    let { selectedStateAndLocation } = this.props.location.state ? this.props.location.state : "";

    let { showtimes } = this.state;

    let uniqueShowtimesWithDates = [];
    let showtimesWithUniqueDates = [];

    if (showtimes.length > 0) {
      uniqueShowtimesWithDates = showtimes.map(function(item, index) {
        console.log(item.mongodate)
        return {
          showtimeId: item["_id"],
          showtimeDate: item.mongodate,
          dateNoTime: item.mongodate.split("T")[0],
        };
      });

      showtimesWithUniqueDates = uniqueObjects(uniqueShowtimesWithDates, "dateNoTime").map(function(item, index) {
        return { showtimeId: item.showtimeId, showtimeDate: item.showtimeDate, unix: (new Date(item.showtimeDate)).getTime()};
      });
    }

    //let now1 = new Date();
    // let showtimeInTheFuture = showtimesWithUniqueDates && showtimesWithUniqueDates.filter((item) => {
    //   return new Date(item.showtimeDate) > now1
    // })

    console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", showtimes)

    showtimesWithUniqueDates.sort((a,b) => (a.unix > b.unix) ? 1 : ((b.unix > a.unix) ? -1 : 0));

    let optionsDate = showtimesWithUniqueDates.map(function(item, index) {
      return (
        <option value={formatDate(new Date(item.showtimeDate))} data-uid={item.showtimeId} data-mongodate={item.showtimeDate} key={item.showtimeDate}>
          {formatDate(new Date(item.showtimeDate))}
        </option>
      );
    });
    return optionsDate;
  }

  componentDidMount() {
    let matchAgainst = this.props.location.state ? this.props.location.state.id : this.props.location.pathname.split("/")[2];
    this.setState({showDateandTime: !(matchAgainst === "5e12263d8ead0e73668b4889")});

    let { selectedStateAndLocation } = this.props.location.state ? this.props.location.state : "";

    if (selectedStateAndLocation) {
      let {location, state, movie, movieId, cinemas, selectedCinema, date,} = selectedStateAndLocation;

      if (selectedStateAndLocation.location) {
        this.getCinemasInState(state, cinemas);
        this.handleInputChange({target: {value: location, name: "location", fromFilter: true}}, movieId, selectedCinema, movie);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props || prevState !== this.state) {
      if (prevProps.showtime !== this.props.showtime || prevProps.showtime.length !== this.props.showtime.length) {
        let shows = []
        for (let i = 0; i < this.props.showtime.length; i++) {
          let ele = this.props.showtime[i];
          ele.date = ele.date.split(".")[0]
          ele.mongodate = ele.mongodate.split(".")[0]
          shows.push(ele)
        }
        this.setState({ showtimes: shows });
        this.getMovieDate();

        let { selectedStateAndLocation } = this.props.location.state ? this.props.location.state : "";
        if (selectedStateAndLocation && selectedStateAndLocation.date) {
          let { showtime } = this.props;
          let selectedDate = selectedStateAndLocation.date.split("T")[0];
          let thedates = showtime.filter((item) =>
            item.mongodate.startsWith(selectedDate)
          );

          this.refreshState({ time: 0, selectedShowTimeID: "" });
          let selectedMongoDate = thedates[0] ? thedates[0].mongodate : ""; //event.target[event.target.selectedIndex].getAttribute('data-mongodate');
          this.setState({ selectedMongoDate: selectedMongoDate });
          this.setState({date: formatDate(new Date(selectedStateAndLocation.date))});

          let dateNshowtimes = showtime.map((item) => {return { showtimeId: item["_id"], showtimeDate: item.mongodate }});
          this.setState({ datesWithShowtimeIds: dateNshowtimes });
          this.getTimeOptions(true, selectedStateAndLocation.date, dateNshowtimes);

          this.setState({soldoutStatus: "", seatsAvailable: 0, isSoldOut: false});
        }
      }
      //this is needed because it is not seeing this.props.showtime to be
      //different when the length is the same (shallow compare issue)
      if (prevProps.showtime.length === this.props.showtime.length && prevState.location !== this.state.location && this.props.showtime.length >= 1) {
        let shows = []
        for (let i = 0; i < this.props.showtime.length; i++) {
          let ele = this.props.showtime[i];
          ele.date = ele.date.split(".")[0]
          ele.mongodate = ele.mongodate.split(".")[0]
          shows.push(ele)
        }
        this.setState({ showtimes: shows });
        //this.setState({ showtimes: this.props.showtime });
      }

      if (this.state.date !== prevState.date || this.state.location !== prevState.location) {
        this.getMovieDate();
      }

      this.props.cinemas && this.getCities();
      this.props.cinemas && this.getStates();
      this.state.state && this.getCinemasInState(this.state.state);
      //this.state.selectedCinemaId && this.getShowtimes(this.state.selectedCinemaId, this.props.movieId);
    }
    // if(prevState.showtimes !== this.state.showtimes && (this.state.showtime.length > 0)){
    //   this.getMovieDate();

		// }
    if (prevState.selectedTicketTypesWithQty !== this.state.selectedTicketTypesWithQty) {
			let price = 0;
			let place = {...this.state}
			let ticketsNow = this.state.selectedTicketTypesWithQty;
			ticketsNow.forEach(item => {
					if (place.state === 'Abuja' && Number(item.quantity) === 3) {
						price = 12500
					} else {
						price += parseInt(item.price) * parseInt(item.quantity);
					}
			});
			this.setState({ price: price });
			}

    //if (this.state.formSubmitted/* && (this.state.formSubmitted !== prevState.formSubmitted)*/) {

    if (this.props.soldout && this.props.soldout.soldout) {
      //console.log(`filmhouseSiteId: ${this.state.filmhouseSiteId}, stid: ${this.state.filmhouseShowtimeId}`)
      this.getSeatsAvailable(this.state.filmhouseSiteId, this.state.filmhouseShowtimeId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.cinemas !== nextProps.cinemas || this.state.selectedMongoDate !== nextState.selectedMongoDate) {
      return true;
    }
    if (
      this.state.cities.length !== nextState.cities.length ||
      this.state.city !== nextState.city ||
      this.state.date !== nextState.date ||
      this.props.showtime.length !== nextProps.showtime.length ||
      this.state.state !== nextState.state ||
      this.state.states.length !== nextState.states.length ||
      this.state.location !== nextState.location ||
      this.state.locations.length !== nextState.locations.length
    ) {
      return true;
    }

    if (
      this.state.time !== nextState.time ||
      this.state.date !== nextState.date ||
      this.state.time !== nextState.time ||
      this.state.formSubmitted !== nextState.formSubmitted ||
      this.props.soldout !== nextProps.soldout ||
      this.state.filmhouseSiteId !== nextState.filmhouseSiteId ||
      this.state.selectedShowTimeID !== nextState.selectedShowTimeID ||
      this.state.soldoutStatus !== nextState.soldoutStatus ||
      this.state.filmhouseShowtimeId !== nextState.filmhouseShowtimeId ||
      this.state.seatsAvailable !== nextState.seatsAvailable ||
      this.state.isSoldOut !== nextState.isSoldOut ||
      this.state.totalChosenTickets !== nextState.totalChosenTickets
    ) {
      return true;
    }

    if (
      this.state.datesWithShowtimeIds !== nextState.datesWithShowtimeIds ||
      this.state.datesWithShowtimeIds.length !==
        nextState.datesWithShowtimeIds.length ||
      this.state.selectedShowTimeID !== nextState.selectedShowTimeID
    ) {
      return true;
    }
    // if (this.state.selectedCinemaId !== nextState.selectedCinemaId) {
    //   return true;
    // }
    if (
      this.state.ticketType !== nextState.ticketType ||
      this.state.selectedTicketTypesWithQty !==
        nextState.selectedTicketTypesWithQty
    ) {
      return true;
    }

    if (
      this.state.price !== nextState.price ||
      this.state.movieType !== nextState.movieType
    ) {
      return true;
    }

    // if ((this.state.secondaryPrice !== nextState.secondaryPrice) || (this.state.movieType !== nextState.movieType)) {
    // 	return true;
    // }

    if (this.state.showDateandTime !== nextState.showDateandTime) {
      return true;
    }

    if (this.state.customerEmail !== nextState.customerEmail) {
      return true;
    }

    if (this.state.customerPhone !== nextState.customerPhone) {
      return true;
    }

    // if (this.state !== nextState.props) {
    //   return true;
    // }

    return false;
  }

  getSelectedShowtimeObj = (id, showtimeObjs, date) => {
    let showtimesFiltered = showtimeObjs.filter(
      (item) => item._id === id && item.mongodate === date
    );

    return showtimesFiltered[0];
  };

  render() {
    let {
      selectedShowTimeID,
      selectedMongoDate,
      showtimes,
      selectedTicketTypesWithQty,
      seatsAvailable,
      totalChosenTickets,
      state,
      location,
      date,
      time,
			showDateandTime,
			carType,
    } = this.state;

    let toRet, theTicketTypes, selectedShowtimeObj, theSelectedShowtimeObj;

    if (showtimes.length >= 1) {
      if (selectedShowTimeID) {
        selectedShowtimeObj = this.getSelectedShowtimeObj(selectedShowTimeID, showtimes, selectedMongoDate);
      }
      if (!selectedShowTimeID) {
        toRet = null;
      } else if (selectedShowTimeID && selectedShowtimeObj) {
        theSelectedShowtimeObj = selectedShowtimeObj;
        theTicketTypes = theSelectedShowtimeObj.ticketTypes;
				let tickets = Object.keys(theTicketTypes);

        toRet = tickets.map((item, index, arr) => {
          let ticketPrice = theTicketTypes[item].price;
					let ticketId = theTicketTypes[item].id;

          return (
						<div className="input-group"> 
							<div className="row buy-ticket_type">
                <div className="col-md-9 ticket-type">
                  {item}: ₦{ticketPrice}
                </div> 
                <div className="col-md-3">      
                  <select className="custom-select" name={`ticketType_${item}`}  value={this.state.ticketType[item]}
                    onChange={this.handleTicketType.bind(null, ticketPrice, ticketId )}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                </div>
              </div>
            </div>
          );
        });
      }
    }

    let isSubmitable = !showDateandTime? !state || !location || !date || selectedTicketTypesWithQty.length === 0 : !state || !location || !date || !time || selectedTicketTypesWithQty.length === 0;

    let buy = (
      <div className="col-md-4 buy-ticket-md-section">
			{ /*<DriveInModal /> */}
        <div className="row pb-5">
          <div className="col-12 col-md-12">
            <div className="section-title">Buy Ticket</div>
          </div>
        </div>
        <div className="movie__ticket-form">
          <p style={{color: "red"}}>{window.location.href.includes("613b5ffc8ead0e8b648b4574") ? "Please note the ticket purchased is valid from October 8th-31st 2021 at any Genesis Cinema location. Kindly select the date and time provided below." : "*It is advisable to select a showtime that is at least 30 minutes ahead to avoid last minute issues."}</p>
          <div className="input-group">
            <p>
              Select State:
              <select className="custom-select" name="state" value={this.state.state} onChange={this.handleInputChange}>
                <option value={0} key={"default"}>
                  Select State
                </option>
                {this.getLocationOptions(this.state.states)}
              </select>
            </p>
          </div>

          <div className="input-group">
            <p>
              Select Cinema:
              <select className="custom-select" name="location" value={this.state.location} onChange={this.handleInputChange}>
                <option value={0} key={"default"}>
                  Select Cinema
                </option>
                {this.getCinemasInState(this.state.state)}
              </select>
            </p>
          </div>

          {/* <div className="input-group">
            <p>
              Select Car Type:
              <select className="custom-select" name="carType" value={this.state.carType} onChange={this.handleInputChange}>
                <option value={""} key={"default"}>Select Car Type</option>
                {carTypes.map(cType => (
                  <option value={cType.value} key={cType.id}> {cType.value} </option>
                ))}
              </select>
            </p>
          </div> */}

          <div className="input-group">
            <div className="row">
              <div className={showDateandTime ? "col-md-6" : "col-md-12"}>
                <p>
                  Preferred Date:
                  <select className="custom-select" name="date" value={this.state.date} onChange={this.handleInputChange}>
                    <option value={0}>Select Date</option>
                    {this.getDateOptions()}
                  </select>
                </p>
              </div>

              <div className={showDateandTime ? "col-md-6" : "d-none"}>
                <p>
                  Preferred Time:
                  <select className="custom-select" name="time" value={this.state.time} onChange={this.handleInputChange}>
                    <option value={0}>Select Time</option>
                    {this.getTimeOptions()}
                  </select>
                  {/**
										<select className="custom-select" name="time" value={this.state.time} onChange={this.handleInputChange}>
										<option value={0}>Select Time</option>
										{this.getTimeOptions()}
									</select> 
									 */}
                </p>
              </div>
            </div>
          </div>

          <div className="input-group">
            <div className="row mb-4">
              {/* {
								showDateandTime ?
									selectedShowTimeID && (<div className="col-md-12">Choose Ticket Type</div>)
									: location && state ? (<div className="col-md-12">Choose Ticket Type</div>) : null
							} */}

              {/* {selectedShowTimeID && (
                <div className="col-md-12">Number of adult</div>
              )} */}
            </div>
          </div>
          {toRet}

          {/* {
						showDateandTime ? toRet
							: location && state ? <div className="input-group">
								<div className="row buy-ticket_type">
									<div className="col-md-9 ticket-type">Quantity: ₦2000</div>
									<div className="col-md-3">
										<select className="custom-select" onChange={this.handleUpdatePrice}>
											<option value="0">0</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
										</select>
									</div>
								</div>
							</div> : null
					} */}

          <div className="input-group">
            <div className="input-group movie__total">
              {this.state.seatsAvailable !== 0 && (
                <p>seats available: {this.state.seatsAvailable}</p>
              )}
              <p>
                {seatsAvailable < totalChosenTickets
                  ? `sold out! ${seatsAvailable} tickets left`
                  : ""}
              </p>
              <span className="total">Total</span>
              <span className="price">
                <span className="naira_sign">₦</span>
                {this.state.price}
                {/* {
									!showDateandTime ? this.state.secondaryPrice : this.state.price
								} */}
              </span>
            </div>

            <div className="input-group">
              <button className="ticket-button block-btn" disabled={isSubmitable} onClick={this.handleFormSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    );

    let upcoming = (
      <div className="col-md-4 buy-ticket-md-section">

        <div className="row pb-5">
          <div className="col-12 col-md-12">
            <div className="section-title">GET NOTIFIED</div>
          </div>
        </div>
        <div className="movie__ticket-form">
          <p style={{fontSize: "16px"}}>This movie is currently not showing please provide your email to get notified when the movie starts showing at the cinemas.</p>

          <div className="input-group">
            <p>
              Your Phone Number:
              <input type="text" value={this.state.customerPhone} onChange={this.phoneInputChange} placeholder="23488223344555" />
            </p>
          </div>

          <div className="input-group">
            <p>
              Your email address:
              <input type="text" value={this.state.customerEmail} onChange={this.emailInputChange} placeholder="you@domain.com" />
            </p>
          </div>

          <div className="input-group">
            <button className="ticket-button block-btn" disabled={this.state.emailSubmitable} onClick={this.handleEmailFormSubmit}>Submit</button>
          </div>
      </div>
      </div>
    );
    return this.state.states.length ? buy : upcoming
  }
}

function mapStateToProps(state) {
  let { showtime } = state.showtime;
	let { soldout } = state;

  return {
    showtime,
    soldout,
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators({ getShowTimes, getSoldout }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyTicketForm);
