import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { getMovies } from '../redux/actions/movies';
// import MovieItem from './MovieItem';
import FeaturedMovieItem from './FeaturedMovieItem';

class FeaturedMovieList extends Component {
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(getMovies());
    }
    render() {
        var settings = {
            autoplay: true,
            dots: false,
            autoplaySpeed: 6000,
            infinite: true,
            arrows: false,
            speed: 6000,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 780,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        };
        let { movies } = this.props
        //console.log('movies length: ', movies.length)

        let toRender = movies.map(function (item, index, arr) {
            return <FeaturedMovieItem movie={item} key={item.id} />
        })
        return (
            <div className="container section-separator featured-container">
                <div className="featured-slides">
                    <Slider {...settings}>
                        {/* <div>
                            <div className="feat_item">
                                <div className=" text-center featured_item overlay-card ">
                                    <img src="https://res.cloudinary.com/dwa8k0pds/image/upload/v1558520807/nokia_258x382.svg" alt="slider" className="img-responsive image" />
                                    <div className="middle">
                                        <div className="overlaycontent" >
                                            <div className="btn ticket-button featured-ticket"

                                            // pathname: `/movies/${slugify(title)}`,
                                            // pathname: `/movies/${id}`,
                                            // state: { id, title, description }

                                            ><a href="https://nokia.ly/2Vfr9Vb">Learn More</a></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {toRender}


                    </Slider>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    let theMovies = state.movies.movies;
    let featureMovies = theMovies.filter(function (item, index, arr) {
        return !!item.featured
    })
    //console.log(`featuredMovies: ${JSON.stringify(featureMovies, null, 4)}`);

    return {
        movies: featureMovies //state.movies.movies.slice(0, 6),
    }
    //const { movies } = state.movies
}

export default connect(mapStateToProps)(FeaturedMovieList)