export default {
    // api: 'http://localhost:3000/v2',
    api: 'https://movies-apis.nairabox.net/v2', //tickets/?as=movies
    apiProduction: 'https://movies-apis.nairabox.net/v2',
    visaApi: 'https://movie-visa.herokuapp.com/api/v1',
    raveLive: 'FLWPUBK-373d4dac0fc7efb9f265082490504ba0-X',
    raveTest: 'FLWPUBK-a438c9cc969e09b8a91c24d2bedc3670-X',

    subPublicKey: 'FLWPUBK-1fa7ccbbbf4f6975ff5fa709d4934ad3-X',
    subSeckey: 'FLWSECK-186f212d2faa0fd6bca48d04dea17635-X',

    baseUrl: 'https://movies-apis.nairabox.net',
    //https://movies-apis.nairabox.net/api/cinemas/displaySiteShowtime/3f2f862b-2702-4ea3-4385-08d5c686e473
}
// v1/tickets/
