import React from "react";
import Autosuggest from "react-autosuggest";
import { Link } from "react-router-dom";
import "../assets/css/autosuggest.css";

class MyAutoSuggest extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      suggestions: []
    };
  }

  getSuggestionValue = suggestion => {
    return suggestion.name;
  };
  renderSuggestion = ({ title, id, description }) => {
    return (
      <div className="myBox2">
        <Link
          to={{
            pathname: `/movies/${id}`,
            state: { id, title, description }
          }}
        >
          {" "}
          {title}{" "}
        </Link>
      </div>
    );
  };
  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    let { value, suggestions } = this.state;
    let noValue = value && suggestions.length === 0;
    return (
      <div className={"suggestionsContainer"} {...containerProps}>
        {children}
        <div>{noValue && <span> no result </span>}</div>
      </div>
    );
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    console.log("this.props.data: ", this.props.data);

    return inputLength === 0
      ? []
      : this.props.data.filter(
        item => item.title.toLowerCase().includes(inputValue)
          //slice(0, //inputLength) === inputValue
        );
  };

  // return inputLength === 0
  //     ? []
  //     : this.props.data.filter(
  //         item => item.title.toLowerCase().slice(0, inputLength) === inputValue
  //       );
  // };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      className: "nav__search-input",
      name: "search",
      placeholder: "Search for movie",
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        className="nav__search-input"
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    );
  }
}

export default MyAutoSuggest;
