import axios from 'axios';
import globals from '../../globals';
//import secrets from '../../secrets';
import { GET_MOVIES_PENDING, GET_MOVIES_SUCCESS, GET_MOVIES_FAILED, LOADING_START, LOADING_END } from '../actions/types'

//('globals.api: ', globals.api);

//let authId = secrets.apiKey;
// let authIdTest = 'abcdefghijkl1234';
let as = 'movies';
let moviesPath = `${global.nairaboxENV(1)}/v2/tickets?&as=${as}`;

//('moviesPath: ', moviesPath);

//https://nairaboxapi.herokuapp.com/v1/tickets?auth=abcdefghijkl1234&as=cinemas

export function getMovies() {
    return (dispatch) => {
        dispatch({ type: LOADING_START, loadingText: ' ... loading movies' })
        dispatch({ type: GET_MOVIES_PENDING });
        return axios.get(moviesPath)
            .then(function (response) {

                dispatch({ type: LOADING_END })
                let theMovies = response.data.movies;

                let avengers = theMovies.filter((item, index) => {
                    let itemLowerCased = item.title.toLowerCase();
                    return itemLowerCased.indexOf('avengers') !== -1
                })

                let withoutAvengers = theMovies.filter((item, index) => {
                    let itemLowerCased = item.title.toLowerCase();
                    return itemLowerCased.indexOf('avengers') === -1
                })


                let withAvengersInFront = avengers && withoutAvengers.unshift(...avengers);

                let avengersInFront = withoutAvengers;

                dispatch({ type: GET_MOVIES_SUCCESS, payload: avengersInFront });
            })
            .catch(function (error) {
                dispatch({ type: LOADING_END })
                dispatch({ type: GET_MOVIES_FAILED, payload: error });
            });
    }
}

