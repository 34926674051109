import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../helpers';

export default class ComingSoonMovieItem extends Component {
    render() {
        const { featuredImage, title, description, id } = this.props.movie;
        return (
            <div>
                <div className="feat_item">
                    <div className=" text-center featured_item overlay-card ">
                        <img src={featuredImage} alt="slider" className="img-responsive comingsoon_image" />
                        <div className="middle">
                            <div className="overlaycontent" >
                            <Link className="btn ticket-button featured-ticket"
                                to={{
                                    // pathname: `/movies/${slugify(title)}`,
                                    pathname: `/movies/${id}`,
                                    state: { id, title, description }
                                }}
                            >Buy Ticket</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}