import axios from 'axios';
import globals from '../../globals';
//import secrets from '../../secrets';
import { GET_MOVIE_PENDING, GET_MOVIE_SUCCESS, GET_MOVIE_FAILED, LOADING_START, LOADING_END } from '../actions/types'

let as = 'moviedetail';
let moviesPath = `${global.nairaboxENV(1)}/v2/tickets?&as=${as}`;

export function getMovie(id) {
    return (dispatch) => {
        dispatch({ type: LOADING_START, loadingText: ' ... loading movie' })
        dispatch({ type: GET_MOVIE_PENDING });
        return axios.get(`${moviesPath}&mid=${id}`)
            .then(function (response) {
                dispatch({ type: LOADING_END })
                //('single movie response: ', response);
                //('single movie response.data.movies: ', response.data.movies);
                dispatch({ type: GET_MOVIE_SUCCESS, payload: response.data.movies });
            })
            .catch(function (error) {
                dispatch({ type: LOADING_END })
                //('Problem getting single movie. Error: ');
                //(error);
                dispatch({ type: GET_MOVIE_FAILED, payload: error });
            });
    }
}
