import axios from 'axios';
import globals from '../../globals';
import { uniqueObjects } from '../../helpers';
import {
    GET_MOVIES_FILTER_BYCINEMA_PENDING, GET_MOVIES_FILTER_BYCINEMA_SUCCESS,
    GET_MOVIES_FILTER_BYCINEMA_FAILED, LOADING_START, LOADING_END
} from '../actions/types'

//https://movies-apis.nairabox.net/v2/tickets?as=filterByCinema&cinema=2019-06-11T09:30:00.0Z
let as = 'filterByCinema';
let moviesPath = `${global.nairaboxENV(1)}/v2/tickets?&as=${as}&cinema=`;

export function getMoviesFilterByCinema(cinemaUid) {
    return async (dispatch) => {

        dispatch({ type: LOADING_START, loadingText: ' ... loading movies' })
        dispatch({ type: GET_MOVIES_FILTER_BYCINEMA_PENDING });

        try {

            let dresponse = await fetch(moviesPath + cinemaUid);
            let resJson = await dresponse.json();

            const unique = [...new Set(resJson.map(item => item.ticketID))];
            const noUndefined = unique.filter(item => !!item);

            const newUnique = uniqueObjects(noUndefined, '_id');
            console.log('newUnique: ', newUnique);

            dispatch({ type: LOADING_END })
            dispatch({ type: GET_MOVIES_FILTER_BYCINEMA_SUCCESS, payload: newUnique });

        } catch (error) {
            console.log('error in moviesfilter: ', error);
            dispatch({ type: LOADING_END })
            dispatch({ type: GET_MOVIES_FILTER_BYCINEMA_FAILED, payload: error });
        }
    }
}

