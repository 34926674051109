import React from 'react';

const FooterTop = props => (
  <div className="container section-separator">
    <div className="footer-top">
      <span>Follow us:</span>
      <span>
        <a href="https://www.facebook.com/nairabox" target="_blank">
          <img src="../assets/images/icons/facebook.png" alt="" />
        </a>
        <a href="https://www.instagram.com/nairabox" target="_blank">
          <img src="../assets/images/icons/instagram.png" alt="" />
        </a>
        <a href="https://www.twitter.com/nairabox" target="_blank">
          <img src="../assets/images/icons/twitter.png" alt="" />
        </a>
      </span>
    </div>
  </div>
);

export default FooterTop;
