import axios from 'axios';
import globals from '../../globals';
//import secrets from '../../secrets';
import {
    GET_TICKET_SHOWTIME_PENDING, GET_TICKET_SHOWTIME_SUCCESS,
    GET_TICKET_SHOWTIME_FAILED, LOADING_START, LOADING_END
} from '../actions/types'

let as = 'showtimes';
let moviesPath = `${global.nairaboxENV(1)}/v2/tickets?&as=${as}`;
//let filmhouseUrl = `${globals.api}/tickets?as=test`;
let filmhouseUrl = `${global.nairaboxENV(1)}/v2/tickets/?as=test`;

//https://movies-apis.nairabox.net/v2/tickets?as=test&filmId=3f2f862b-2702-4ea3-4385-08d5c686e473
//https://nairaboxapi.herokuapp.com/v1/tickets?auth=abcdefghijkl1234&as=cinemas

export function getShowTimes(cinemaHouseId, movieId, siteId, filmhouseId) {
    return (dispatch) => {
        dispatch({ type: LOADING_START, loadingText: ' ... loading show times' })
        dispatch({ type: GET_TICKET_SHOWTIME_PENDING });
        let generalShowtimesUrl = `${moviesPath}&cinemaId=${cinemaHouseId}&ticketId=${movieId}`;

        return axios.get(generalShowtimesUrl)
            .then(function (response) {
                dispatch({ type: LOADING_END })
                dispatch({ type: GET_TICKET_SHOWTIME_SUCCESS, payload: response.data.showtimes });
            })
            .catch(function (error) {
                dispatch({ type: LOADING_END })
                dispatch({ type: GET_TICKET_SHOWTIME_FAILED, payload: error });
            });
    }
}
