global.nairaboxENV = function(url = false){

    let fields = 
    {
        RAVE_PUB_KEY: "FLWPUBK-a438c9cc969e09b8a91c24d2bedc3670-X",
        MOVIES_API_LOCAL: "http://localhost:5001",
        MOVIES_API_HEROKU: "https://nairabox-movie-api.herokuapp.com",
        MOVIES_API_LIVE: "https://movies-apis.nairabox.net",
        HASH: "NairaboxTeniolaJSON",
        VISA_API: "https://movie-visa.herokuapp.com/api/v1",
        raveLive: 'FLWPUBK-373d4dac0fc7efb9f265082490504ba0-X',
        raveTest: 'FLWPUBK-47596ce3e8bac020581d67b42419e4c8-X',
    
        subPublicKey: 'FLWPUBK-1fa7ccbbbf4f6975ff5fa709d4934ad3-X',
        subSeckey: 'FLWSECK-186f212d2faa0fd6bca48d04dea17635-X',
    
        baseUrl: 'https://movies-apis.nairabox.net',
    }


    const host = window.location.host;
    
    let dev = "live";

    let h = host.split(":")

    if (h[1] && h[1] == 3000) dev = "local"

    if (h[1] && h[1] == 3011) dev = "heroku"

    if (host.includes("herokuapp")) dev = "heroku"

    if(url == 1){

        if(dev == "live"){
            return fields.MOVIES_API_LIVE
        }
        if(dev == "local"){
            return fields.MOVIES_API_LOCAL
        }
        if(dev == "heroku"){
            return fields.MOVIES_API_HEROKU
        }
        
    }


    if(fields[url]){
        return fields[url]
    }

    console.log(dev)
    return dev
};