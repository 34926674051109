import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMovies } from "../redux/actions/movies";
import { Carousel } from "react-bootstrap";
import { generate } from "randomstring";
import { Link } from "react-router-dom";
import TextTruncate from "react-text-truncate";

class Header extends Component {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);

		this.state = {
			index: 0,
			direction: null
		};
		this.state = {
			modal: false,
			isOpen: false,
			movieid: ""
		};

		//this.toggle = this.toggle.bind(this);
		this.openModal = this.openModal.bind(this);
	}

	openModal(movieid) {
		this.setState({ isOpen: true, movieid });
	}

	componentDidMount() {
		const { getMovies } = this.props;
		getMovies();
	}

	handleSelect(selectedIndex, e) {
		this.setState({
			index: selectedIndex,
			//direction: e.direction
		});
	}

	render() {
		const { index, direction } = this.state;

		let toRet =
			this.props.movies && 
			this.props.movies.map((item, index) => {
				let {
					bannerImage,
					sliderImage,
					title,
					description,
					youtubeid,
					id,
					genre
				} = item;

				return (
					<Carousel.Item>
						<div className="overlay overlay-static-page" />
						<img
							className="carouselImage img-fluid"
							src={sliderImage}
							alt="Third slide"
							width="100%"
							height="100%"
							alt="bgimage"
							background="#777"
							color="#777"
							text=" "
							title=" "
						/>
						{/* <Carousel.Caption className="text-left">
              <div className="caption__tags ">
                <ul>
                  {genre.split(",").map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
              <h1> {title} </h1>
              {description.substr(0, 200) + "..."}
              <p />
              <p>
                <span className="trailer-span">
                  <img
                    src="../assets/images/icons/watch-trailer.png"
                    alt="watch-trailer-icon"
                  />{" "}
                  <a
                    href="#/watchtrailer"
                    onClick={() => this.openModal(youtubeid)}
                  >
                    Watch Trailer
                  </a>
                </span>

                <Link
                  className="btn btn-lg ticket-button"
                  role="button"
                  to={{
                    pathname: `/movies/${id}`,
                    state: { id, title, description }
                  }}
                >
                  {" "}
                  Buy Ticket{" "}
                </Link>
              </p>
            </Carousel.Caption> */}
					</Carousel.Item>
				);
			});

		let singleCarousel = this.props.movies && this.props.movies.length > 0 ?
			<Carousel.Item>
				<div className="overlay overlay-static-page" />
				<img className="carouselImage img-fluid" src={this.props.movies[0].sliderImage} alt="Third slider" width="100%" height="100%" alt="bgimage"
					background="#777" color="#777" text=" " title=" " />

			</Carousel.Item> : null

		return (
			<Carousel
				activeIndex={index}
				direction={direction}
				onSelect={this.handleSelect}
				indicators={false}
			>
				{/* <Carousel.Item>
          <div className="overlay overlay-static-page" />
          <img
            className="carouselImage"
            src="https://res.cloudinary.com/dwa8k0pds/image/upload/v1558522697/nokia_ad_1440x772.svg"
            alt="Third slide"
            width="100%"
            height="100%"
            alt="bgimage"
            background="#777"
            color="#777"
            text=" "
            title=" "
          />

          <Carousel.Caption className="text-left">
            <div className="caption__tags " />
            <div className="btn ticket-button featured-ticket">
              <a href="https://nokia.ly/2Vfr9Vb">Learn More</a>
            </div>
          </Carousel.Caption>
        </Carousel.Item> */}

				{/* {toRet} */}

				{singleCarousel}


			</Carousel>
		);
	}
}

function mapStateToProps(state) {
	let theMovies = state.movies.movies;
	let featureMovies = theMovies.filter(function (item, index, arr) {
		return !!item.featured;
	});

	return {
		movies: featureMovies.slice(0, 3)
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return bindActionCreators({ getMovies }, dispatch);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);
