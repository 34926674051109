import React, { Component } from 'react'

class MovieVisa extends Component {
  render() {
    return (
      <div className="section-separator advert">
      <div className="container ">
        <div className="row">
          <div className="col-12 col-md-5 ad-image">
            <img src="assets/images/pepsi-ad.png" alt="" />
          </div>
          <div className="col-12 col-md-7 ad-profile">
            <div className="ad-title">
              With MoviePass you can Watch 3 Movies for &#8358;1000
            </div>
            <p className="ad-description">
              Sign up today and get the best deals. This service gives
              you access to your favourite movies nationwide. Nairabox
              Moviepass Subscription  allows you see 3 movies every month,
              Subscribe today. Click below to get started
            </p>
            <button className="ticket-button">Learn More</button>
          </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MovieVisa;