import React from 'react';
import RavePaymentModal from 'react-ravepayment';
import swal from 'sweetalert';
import globals from '../globals';
import axios from 'axios';

import PaystackButton from 'react-paystack';

class Payment extends React.Component {

	close = () => {
		console.log("Payment closed");
	}

	state = {
        key: global.nairaboxENV() == 'local' || global.nairaboxENV() == 'heroku' ? "pk_test_38169a739b51c5c7139972b3caf86d4f3d4b94c7" : "pk_live_41ed986b3773595273e995adf63505fa657e6bfc",
		email: this.props.userDetails && this.props.userDetails[0].email,
		amount: this.props.amount, // equals NGN 1000. Minimum amount allowed NGN 1 while on production or live system, it's 10
		metadata: [],
		phone: '',
		fullname: '',
		amount: 0,
		email: ''
	};

	componentDidMount() {
		const { amount, movieId, selectedCinema, movie, amountBeforeVoucher } = this.props;
		const { id, name, location_id, cinemaChainId, filmHouseId } = selectedCinema;
		const userDetails = this.props.userDetails && this.props.userDetails[0];

		const users = this.props.userDetails && JSON.stringify(this.props.userDetails)
		const ticketInfo = this.props.ticketInfo && JSON.stringify(this.props.ticketInfo)
		const totalTickets = this.props.totalTickets && JSON.stringify(this.props.totalTickets)

		this.setState({
			metadata: [
				{ metaname: "firstname", metavalue: userDetails && userDetails.fullname },
				{ metaname: "amount", metavalue: amount },
				{ metaname: "amountBeforeVoucher", metavalue: amountBeforeVoucher },
				{ metaname: 'userDetails', metavalue: users },
				{ metaname: 'ticketInfo', metavalue: ticketInfo },
				{ metaname: 'totalTickets', metavalue: totalTickets },
				{ metaname: 'voucher', metavalue: this.props.voucher || null },
				{ metaname: 'carType', metavalue: this.props.carType || null }
			], phone: userDetails && userDetails.phone,
			fullname: userDetails && userDetails.fullname,
			amount: amount,
			email: userDetails && userDetails.email
		});
	}

	callback = async (response) => {


		// Set custom meta information
		//response.tx.customMetadata = this.state.metadata || [];

		// verify transaction
		axios.post(`${global.nairaboxENV(1)}/v2/tickets?as=verify-paystack`, {toSend: this.props.toSend, ref:  response.reference}, {
			headers: {
				'verif-hash': global.nairaboxENV("HASH")
			}
		}).then(resp => {
			window.location.href = resp.data.url;
			console.log("++++++++++++++++++++++++++++++++++++", resp.data);
			swal('Ticket Purchase Successful, Please check your mail for your E-ticket and close this prompt to download ticket').then(() => {
				//window.location.href = resp.data.url;
			});
		}).catch(err => {

			if (err.response && err.response.data && err.response.data.message) {

				console.log("++++++++++++++++++++++++++++++++++++++++++++++", err.response.data.message);

				swal('Ticket purchase Failed:- ' + err.response.data.message).then(() => {
					//window.location.href = resp.data.url;
				});

			} else {
				swal('Ticket purchase Failed:- ' + err.message).then(() => {
					//window.location.href = resp.data.url;
				});
			}

		});

	};



	render() {
		return (
			<div className="App">
				<p className="App-intro">
                
                <PaystackButton
                text="Make Payment with Paystack"
                class="ticket-button block-btn"
                callback={this.callback}
                close={this.close}
                disabled={false} 
                embed={false} 
                reference={this.props.toSend.ref}
                email={this.state.email}
                amount={this.state.amount * 100}
                paystackkey={this.state.key}
                tag="button"
              />




				</p>
			</div>
		);
	}
}

export default Payment;

