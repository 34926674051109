import React from 'react';

const Footer = props => (
  <div className="section-separator">
      
    <div className="line"></div>
    <div className="container ">
    <div className="footer">
      <div className="col-md-2"><a href="/"><img src="../assets/images/icons/nairabox-icon.png" height="40px" alt="Nairabox Logo" /></a></div>
        <div className="col-md-6 hidden-xs-down footer-item">
        <ul className="">
          {/* <li><a href="/cinema">Cinema</a></li>
          <li><a href="/subscription">Movie Subscription</a></li> */}
          <li><a href="/help">Help</a></li>
          <li><a href="/terms">Terms & Conditions</a></li>
          <li><a href="/terms-subscription">Terms & Conditions for Movie Subscription</a></li>
        </ul>
        </div>

       

      <div className="col-md-4">
      <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.nairabox'><img src="../assets/images/icons/google_play.png" className="mr-3" alt="Download from Playstore" /></a>
      {/* <a target="_blank" rel="noopener noreferrer" href='http://apple.co/2981VfL'> <img src="../assets/images/icons/app_store.png" alt="Download from Appstore" /></a> */}
      </div>
      </div>
    </div>
  </div>
);

export default Footer;