import axios from 'axios';
import globals from '../../globals';
import { uniqueObjects } from '../../helpers';
import { GET_MOVIES_FILTER_PENDING, GET_MOVIES_FILTER_SUCCESS, GET_MOVIES_FILTER_FAILED, LOADING_START, LOADING_END } from '../actions/types'

// https://movies-apis.nairabox.net/v2/tickets
// ?as=filterByDate&date=2019-06-11T09:30:00.0Z
let as = 'filterByDate';
let moviesPath = `${global.nairaboxENV(1)}/v2/tickets?&as=${as}&date=`;

export function getMoviesFilter(date) {
    return async (dispatch) => {

        dispatch({ type: LOADING_START, loadingText: ' ... loading movies' })
        dispatch({ type: GET_MOVIES_FILTER_PENDING });

        // return axios({
        //     method: 'get',
        //     url: moviesPath,
        //     responseType: 'json',
        //     headers: { 'Content-Type': 'application/json' },
        // })

        try {

            let dresponse = await fetch(moviesPath + date);
            let resJson = await dresponse.json();

            const unique = [...new Set(resJson.map(item => item.ticketID))];
            const noUndefined = unique.filter(item => !!item);

            const newUnique = uniqueObjects(noUndefined, '_id');
            console.log('newUnique: ', newUnique);

            dispatch({ type: LOADING_END })
            dispatch({ type: GET_MOVIES_FILTER_SUCCESS, payload: newUnique });


        } catch (error) {

            console.log('error in moviesfilter: ', error);
            dispatch({ type: LOADING_END })
            dispatch({ type: GET_MOVIES_FILTER_FAILED, payload: error });


        }

        /*
        [
            [
                {
                    adult: "1000"
                    available: 1
                    children: "1000"
                    date: "2019-06-11 11:10:00"
                    day: "Tuesday"
                    limit: 200
                    mongodate: "2019-06-11T10:10:00.000Z"
                    sold: 0
                    student: "1000"
                    ticketID: {_id: "5ce275f98ead0e9f088b4687", name: "ALADDIN", category: "CINEMA",…}
                    uid: "300100"
                    _id: "5cf9022c8ead0eaa048b4620"
                }
            ]
        ]

        */
    }
}

